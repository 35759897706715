import React from 'react';

import PageLayout from '../../layouts/PageLayout';



const ForexSignal = () => {

    return (
        <>
            <div className="page-content">
                <PageLayout pageTitle="Forex Signal" />
                <section className="content-inner about-sec bg-primary-light">
                    <div className="container">
                        <div className="row about-bx2 style-1 align-items-center">
                            <h4>Under Development</h4>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default ForexSignal;