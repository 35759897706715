import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//components
import BannerCard from './../components/Home/BannerCard';
import OneStop from './../components/Home/OneStop';

import Chart from './../components/Home/chart';

//images
import baner1 from './../assets/images/home-banner/img1.png';
import baner2 from './../assets/images/home-banner/img2.png';
import Shape1 from './../assets/images/home-banner/shape1.png';
import Shape3 from './../assets/images/home-banner/shape3.png';
import wallet from './../assets/images/icons/wallet.svg';
import friend from './../assets/images/icons/friend.svg';


const trustBlog = [
	{ image: wallet, title: 'Proven Track Record', description: 'With over 5 years of experience in trading, we have developed a solid track record of success. Our optimized signals and automated trading software for both binary options and forex have consistently delivered profitable results for our clients. We have a deep understanding of the markets and employ advanced strategies to maximize returns. Our proven track record serves as a testament to our expertise and reliability in the trading industry.' },
	{ image: friend, title: 'Expert Education', description: 'In addition to our trading experience, we are passionate about sharing our knowledge with others. We have conducted classes through platforms like Google Meet, providing in-depth insights and training to individuals interested in trading. Our commitment to education sets us apart, as we believe that empowering our clients with knowledge is crucial for their success. By choosing us, you gain access to not only our expertise but also our dedication to helping you understand the intricacies of trading.' },
];


const Home = () => {
	const nav = useNavigate();

	const userid = localStorage.getItem('userid');
	// Automatic redirection to home page if user hasUserId and accesses login page
	useEffect(() => {

		if (userid) {
			nav('/');
		}
	}, [userid, nav]);

	return (
		<>
			<div className="page-content">
				<div className="main-bnr style-1">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 text-center">
								<h1 className="" >Your Globel OTC desk for <br />Dark World</h1>
								{/* <p className="text text-primary " >Transfer USD, EUR, or InfinityTrader and start trading today!</p> */}
								{!userid ?
									<Link to={"/Login"} className="btn space-lg  btn-shadow btn-primary " >Get Started</Link>
									: <span className="welcome btn space-lg  btn-shadow btn-primary ">Welcome</span>
								}
								{/* <div style={{ marginTop: '20px', fontWeight: '700' }}><Link to={"/courses"} className="btn space fa-beat" style={{ fontWeight: '700' }}>Check out our Course Details  &nbsp;<i className="fa-solid fa-arrow-right fa-beat"></i></Link> </div> */}
								<ul className="image-before">
									<li className="left-img"><img src={baner1} alt="" /></li>
									<li className="right-img"><img src={baner2} alt="" /></li>
								</ul>
							</div>
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
					<img className="bg-shape2" src={Shape1} alt="" />
					<img className="bg-shape3" src={Shape3} alt="" />
					<img className="bg-shape4" src={Shape3} alt="" />
				</div>
				<div className="clearfix">
					<div className="container">
						<div className="currancy-wrapper">
							<div className="row justify-content-center">
								<BannerCard />
							</div>
						</div>
					</div>
				</div>
				<section className="clearfix">
					<div className="container">
						<div className="content-inner-1">
							<div className="section-head text-center">
								<h2 className="whitetitle">Why Trust Us?</h2>
								<p>Trust comes from experience. Many of the pleased customers may function as a guide for you.</p>
							</div>
							<div className="row">
								{trustBlog.map((data, ind) => (
									<div className="col-lg-6 m-b30" key={ind}>
										<div className="icon-bx-wraper style-2">
											<div className="icon-media">
												<img src={data.image} alt="" />
											</div>
											<div className="icon-content">
												<h4 className="title">{data.title}</h4>
												<p>{data.description}</p>
												{/* <Link className="btn btn-primary btn-gradient btn-shadow" to={"/about-us"}>Read More</Link> */}
											</div>
										</div>
									</div>
								))}

							</div>
						</div>
					</div>
					{/* <section className="content-inner pricing-plan-wrapper bg-primary-light">
						<img className="bg-shape2" src={Shap1} alt="" />
						<img className="bg-shape3" src={Shap1} alt="" />
						<img className="bg-shape1" src={Shap3} alt="" />
						<img className="bg-shape4" src={Shap3} alt="" />
						<img className="bg-shape5" src={Shap3} alt="" />
						<div className="container">
							<div className="section-head text-center">
								<h2 className="title">Awesome Pricing Plan for InfinityTrader Business</h2>
							</div>
							<div className="row justify-content-center">
								<PriceBlog />
							</div>
						</div>
					</section> */}
					{/* <div className="container">
						<div className="form-wrapper-box style-1 text-center">
							<div className="section-head " >
								<h4 className="title m-t0">How to Purchase from us ?</h4>
							</div>
							<form className="dz-form" onSubmit={(e) => formDetails(e)}>
								<div className="form-wrapper">
									<div className="flex-1">
										<div className="row g-3">
											<div >
												<div><span>Use UPI method to make the payment to below UPI ID<h5>Infinitytrader@okhdfcbank</h5></span></div>
												<div><span>After payment successfull, send the screenshot to InfinityTrader Admin in telegram</span></div>

											</div>


										</div>
									</div>
								</div>
							</form>
						</div>
					</div > */}
					<img className="bg-shape1" src={Shape1} alt="" />
				</section >
				{/* <section className="content-inner bg-light icon-section section-wrapper2"></section> */}
				<section className="icon-section section-wrapper2">
					<div className="container">
						<div className="section-head text-center">
							<h2 className="whitetitle">Live Market watch</h2>
						</div>
						<div className="row sp60">
							<Chart />
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
				</section>
				<section className="content-inner icon-section section-wrapper2">
					<div className="container">
						<div className="section-head text-center">
							<h2 className="whitetitle">One-stop solution to increase your trading skills with <span className="text-primary"> InfinityTrader </span></h2>
						</div>
						<div className="row sp60">
							<OneStop />
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
				</section>
				<section className='technicaldata'>

					<div><div><h4>Technical Details</h4></div><div className='newdata'><iframe title="tech" style={{ marginTop: '-30px', borderRadius: '10px' }} src="https://ssltsw.investing.com?lang=56&forex=160,1646,1,2,3,5,9&commodities=8830,8836,8831,8849,8833,8862,8832&indices=23660,166,172,27,179,53094,170&stocks=345,346,347,348,349,350,352&tabs=1,2,3,4" width="317" height="467"></iframe></div></div>
					<div><div><h4>Live News Update</h4></div><div className='newdata'><iframe title="news" style={{ marginTop: '-30px' }} src="https://sslecal2.investing.com?ecoDayBackground=%23cf0404&columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=25,34,32,6,37,72,71,22,17,51,39,14,33,10,35,42,43,45,38,56,36,110,11,26,9,12,41,4,5,178&calType=day&timeZone=23&lang=56" width="650" height="467" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></iframe></div></div>
				</section>
				<section className="content-inner ">
					<div className="container">
						<div className="text-center">
							<div className="section-head " >
								<h4 className="title m-t0">Disclimar</h4>
							</div>
							<div>
								<p className='text-start'><strong className='whitetitle'>Risk: </strong>Trading in financial markets, including binary options and forex, involves inherent risks. The information and services provided on this website are for educational and informational purposes only and should not be considered as financial advice. Trading decisions should be made based on your own analysis and risk tolerance. It is important to be aware of the risks involved and seek professional advice if needed.</p>

								<p className='text-start'><strong className='whitetitle'>Accuracy: </strong>While we strive to provide accurate and up-to-date information, we cannot guarantee the accuracy, completeness, or reliability of the signals, analysis, or other content on this website. Market conditions are subject to change, and past performance is not indicative of future results. Users are advised to independently verify any information or signals provided and use them at their own discretion.</p>

								<p className='text-start'><strong className='whitetitle'>Software: </strong>Our automated trading software is designed to assist traders in making trading decisions. However, the performance and results of the software may vary, and we cannot guarantee its effectiveness or profitability. Users should thoroughly test and evaluate the software before using it with real funds. We are not responsible for any financial losses or damages resulting from the use of our software.</p>

								<p className='text-start'><strong className='whitetitle'>Educational: </strong>The educational resources, classes, and webinars provided on this website are for educational purposes only. They are not intended to provide specific trading advice or recommendations. The strategies and techniques discussed may not be suitable for all individuals, and users should carefully consider their own financial situation and objectives before applying any information or strategies shared.</p>

								<p className='text-start'><strong className='whitetitle'>Third-Party: </strong>This website may contain links to third-party websites or services. We do not endorse or have control over the content, products, or services provided by these third parties. Users are responsible for conducting their own research and due diligence when engaging with any third-party websites or services.</p>
							</div>
						</div>
					</div >
				</section>
			</div >
		</>
	)
}
export default Home;