import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../layouts/PageLayout';
import axios from 'axios';

const ForexCopyTrade = () => {
    const navigate = useNavigate();
    const [acnumber, setAcnumber] = useState('');
    const [password, setPassword] = useState('');
    const [servername, setServername] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [errors, setErrors] = useState({ acnumber: '', password: '', servername: '', terms: '' });

    // Refs for error fields
    const acnumberRef = useRef(null);
    const passwordRef = useRef(null);
    const servernameRef = useRef(null);
    const termsRef = useRef(null);

    async function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { acnumber: '', password: '', servername: '', terms: '' };

        // Scroll to first error field
        const scrollToError = (ref) => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        };

        if (acnumber === '') {
            errorObj.acnumber = 'MT4 Account Number is Required';
            error = true;
            scrollToError(acnumberRef); // Scroll to account number field if error
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
            if (!errorObj.acnumber) scrollToError(passwordRef); // Scroll to password field if no prior error
        }
        if (servername === '') {
            errorObj.servername = 'Server Name is Required';
            error = true;
            if (!errorObj.acnumber && !errorObj.password) scrollToError(servernameRef); // Scroll to server name field if no prior error
        }
        if (!termsAccepted) {
            errorObj.terms = 'You must accept the Terms and Conditions to proceed';
            error = true;
            if (!errorObj.acnumber && !errorObj.password && !errorObj.servername) scrollToError(termsRef); // Scroll to terms checkbox if no prior error
        }
        setErrors(errorObj);

        if (error) return;

        const requestBody = {
            uuid: localStorage.getItem('uuid'),
            mt4login: acnumber,
            mt4password: password,
            mt4server: servername,
        };

        await axios.post("http://localhost:8787/api/add-mt4-user", requestBody)
            .then(response => response.data)
            .then(data => console.log(data))
            .catch(error => console.error(error?.response?.data?.status));
    }

    return (
        <>
            <div className="page-content">
                <PageLayout pageTitle="Forex Auto Trade" />
                <section className="content-inner about-sec">
                    <div className="container">
                        <div className="row about-bx2 style-1 align-items-center">
                            <div className="row gx-0" style={{ justifyContent: "center" }}>
                                <div className="col-md-6 col-sm-12">
                                    <form className="dz-form pb-3" style={{ lineHeight: '30px' }} onSubmit={onLogin}>
                                        <h3 className="form-title m-t0" style={{ textAlign: 'center', color: 'white' }}>
                                            Auto trade in Forex without VPS
                                        </h3>
                                        <div className="dz-separator-outer m-b5">
                                            <div className="dz-separator bg-primary style-liner"></div>
                                        </div>
                                        <p>Enter your MT4 details.</p>

                                        <div className="form-group mb-3">
                                            <input
                                                ref={acnumberRef}
                                                type="text"
                                                className="form-control"
                                                placeholder="MT4 Account Number"
                                                value={acnumber}
                                                onChange={(e) => setAcnumber(e.target.value)}
                                                autoComplete="off"
                                            />
                                            {errors.acnumber && <div className="text-danger fs-12">{errors.acnumber}</div>}
                                        </div>

                                        <div className="form-group mb-3">
                                            <input
                                                ref={passwordRef}
                                                type="password"
                                                className="form-control"
                                                placeholder="MT4 Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                autoComplete="off"
                                            />
                                            {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                        </div>

                                        <div className="form-group mb-3">
                                            <input
                                                ref={servernameRef}
                                                type="text"
                                                className="form-control"
                                                placeholder="MT4 Server Name"
                                                value={servername}
                                                onChange={(e) => setServername(e.target.value)}
                                                autoComplete="off"
                                            />
                                            {errors.servername && <div className="text-danger fs-12">{errors.servername}</div>}
                                        </div>

                                        <div className="form-group mb-3">
                                            <input
                                                ref={termsRef}
                                                type="checkbox"
                                                id="termsCheckbox"
                                                checked={termsAccepted}
                                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                            />
                                            <label htmlFor="termsCheckbox" style={{ marginLeft: '8px' }}>
                                                I accept the <a href="#" onClick={(e) => { e.preventDefault(); setShowTerms(!showTerms); }}>Terms and Conditions</a>
                                            </label>
                                            {errors.terms && <div className="text-danger fs-12">{errors.terms}</div>}
                                        </div>

                                        {showTerms && (
                                            <div className="terms-content">
                                                <p>By using our auto trade service, you agree to the following terms and conditions:</p>
                                                <p>1. **Risk Acknowledgement**: Trading in the financial markets involves significant risk. You accept that the value of investments can go down as well as up, and you may lose some or all of your capital.</p>
                                                <p>2. **No Liability**: We are not responsible for any financial losses, damages, or profits from your trading activities.</p>
                                                <p>3. **Service Availability**: We are not responsible for any technical failures or interruptions of the auto trade service.</p>
                                                <p>4. **Responsibility for Trades**: The decisions to buy, sell, or hold any securities or trades are solely your responsibility.</p>
                                                <p>5. **No Guarantee of Profits**: We do not provide any guarantees of profitability, and past performance is not indicative of future results.</p>
                                            </div>
                                        )}

                                        <div className="form-group text-left mb-5">
                                            <div style={{ textAlign: 'center' }}>
                                                <button type="submit" className="btn btn-primary dz-xs-flex m-r5" >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default ForexCopyTrade;
