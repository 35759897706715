import React, { useEffect, useRef, useState } from 'react';
import ImagePopup from '../ImagePopup';
import chatimg from '../../assets/images/chats/chatimg.png';
import defaultchatimg from '../../assets/images/chats/defaultprofile.jpg';
import Audioplayer from './Audioplayer';
import Adecode from '../../components/General/Adecode'


const ChatMessages = ({ messages, uuid, adminName }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const containerRef = useRef(null);
    const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(null);

    const handlePlayButtonClick = (index) => {
        if (currentlyPlayingIndex === index) {
            setCurrentlyPlayingIndex(null);
        } else {
            setCurrentlyPlayingIndex(index);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        const containerElement = containerRef.current;
        if (containerElement) {
            containerElement.scrollTop = containerElement.scrollHeight;
        }
    };

    useEffect(() => {
        const containerElement = containerRef.current;
        const handleContainerClick = (event) => {
            const clickedElement = event.target;
            if (clickedElement.className === 'userimgdata') {
                const imageUrl = clickedElement.getAttribute('src');
                handleImageClick(imageUrl);
            }
        };
        containerElement.addEventListener('click', handleContainerClick);

        return () => {
            containerElement.removeEventListener('click', handleContainerClick);
        };
    }, []);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const formatTimestamp = (timestamp) => {
        const dateObj = new Date(timestamp);
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const formatDate = (timestamp) => {
        const dateObj = new Date(timestamp);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return dateObj.toLocaleDateString(undefined, options);
    };

    let prevDate = null;

    return (
        <div id="chat-messages" ref={containerRef}>
            {Object.values(messages).map((message, index) => {
                const isUserMessage = uuid === message.uuid;
                const messageBoxClass = isUserMessage ? 'message-box-user' : 'message-box';
                const messageAlign = isUserMessage ? 'right' : 'left';
                const messageTextColor = isUserMessage ? '#e1e1e1' : '#4d5567';
                const currentDate = formatDate(message.createdDate);
                const isDifferentDate = prevDate !== currentDate;

                if (isDifferentDate) {
                    prevDate = currentDate;
                }
                return (
                    <React.Fragment key={index}>
                        {isDifferentDate && (
                            <div style={{ textAlign: 'center', fontSize: '14px', color: '#808080', margin: '10px 0' }}>
                                {currentDate}
                            </div>
                        )}
                        <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', margin: '5px' }}>
                            {!isUserMessage && (
                                <img
                                    src={message.isAdmin ? chatimg : defaultchatimg}
                                    alt="User Avatar"
                                    style={{ borderRadius: '100%', height: 'auto', width: '25px', marginRight: '3px', marginTop: '5px' }}
                                />
                            )}

                            <div style={{ flex: 1, textAlign: messageAlign }}>
                                <div className={messageBoxClass}>
                                    <div style={{ position: 'relative' }}>
                                        <div>
                                            <span className={!isUserMessage ? "other-user-name" : "user-name"}>
                                                {!isUserMessage && "~ "} {message.isAdmin ? adminName.toUpperCase() : message.userid.toUpperCase()}{isUserMessage && " ~"}
                                            </span>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{
                                                        color: messageTextColor,
                                                        textAlign: !isUserMessage ? 'left' : 'right',
                                                        fontSize: '13px',
                                                        letterSpacing: '0px',
                                                        wordSpacing: '1px',
                                                        padding: !isUserMessage ? '0px 30px 10px 10px' : '0px 10px 10px 30px',
                                                    }}
                                                >
                                                    {message.fileType === 'audio' && (
                                                        <Audioplayer
                                                            url={message.fileUrl}
                                                            fileduration={message.duration}
                                                            isPlaying={currentlyPlayingIndex === index}
                                                            onPlayButtonClick={() => handlePlayButtonClick(index)}
                                                        />
                                                    )}
                                                    <div>
                                                        {message.message && message.message}</div>
                                                    <div>
                                                        {message.createdDate && (
                                                            <span
                                                                className="message-timestamp"
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: !isUserMessage ? '0' : 'unset',
                                                                    left: !isUserMessage ? 'unset' : '0',
                                                                    bottom: '0px',
                                                                    background: !isUserMessage ? '#b0b0b040' : '#00000026',
                                                                    padding: '2px 5px',
                                                                    fontSize: '8px',
                                                                    borderRadius: '5px',
                                                                    margin: '1px 2px',
                                                                    color: !isUserMessage ? '#474b59' : '#fff',
                                                                    whiteSpace: 'nowrap',
                                                                    maxWidth: '80px',
                                                                }}
                                                            >
                                                                {formatTimestamp(message.createdDate)}
                                                            </span>
                                                        )}</div>
                                                </div>

                                            </div>
                                            {message.fileType === 'image' && <img src={message.fileUrl} alt='' className="userimgdata" />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isUserMessage && (
                                <img
                                    src={message.isAdmin ? chatimg : defaultchatimg}
                                    alt="User Avatar"
                                    style={{ borderRadius: '100%', height: 'auto', width: '25px', marginTop: '5px' }}
                                />
                            )}
                        </div>
                    </React.Fragment>
                );
            })}

            {selectedImage && <ImagePopup imageUrl={selectedImage} onClose={handleCloseModal} />}
        </div>
    );
};

export default ChatMessages;
