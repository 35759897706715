import React from 'react';
// import Routes from './route';
import Index from './pages/route';
import { BrowserRouter } from 'react-router-dom';

import "./assets/css/style.css";


const App = () => {
  return (
    <div className="App">
      <Index />
      {/* <BrowserRouter basename="/">
        <div className="page-wraper">
          <Routes />
        </div>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
