import React from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const Layout = (ComposedComponent) => (props) => (
    <>
        <Header {...props} />
        <ComposedComponent {...props} />
        <Footer {...props} />
    </>
);

export default Layout;
