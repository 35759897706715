import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import bg6 from '../../assets/images/background/bg6.jpg'
import axios from 'axios';
import cover from '../../assets/images/course/cover.jpg'

const Link_mt4_id = () => {
    const navigate = useNavigate();
    const [acnumber, setAcnumber] = useState('');
    const [password, setPassword] = useState('');
    const [servername, setServername] = useState('');
    let errorsObj = { acnumber: '', password: '', servername: '' };
    const [errors, setErrors] = useState(errorsObj);
    // const dispatch = useDispatch();

    const userid = localStorage.getItem('userid');
    const uuid = localStorage.getItem('uuid');
    // Automatic redirection to home page if user hasUserId and accesses login page
    useEffect(() => {
        if (!userid) {
            alert('Please login to continue.')
            navigate('/login');
        }
    }, [userid, navigate]);

    function AddMT4Id(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (acnumber === '') {
            errorObj.acnumber = 'MT4 Account Number is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        } else {
            // Make the API request
            var requestBody = {
                uuid: uuid,
                mt4loginid: acnumber
            };
            axios.post("https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/add-mt4-loginid", requestBody)
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            alert(response.error);
                            return;
                        }
                    }
                    return response;
                })
                .then(data => {
                    if (data?.status === 201) {
                        alert(data.data.status)
                    }
                })
                .catch(error => {
                    console.error("error.response.data.error");
                    alert(error.response.data.status);
                });
        }

        // dispatch(loadingToggleAction(true));
        // dispatch(loginAction(acnumber, password, navigate));
    }
    return (
        <>
            <div style={{ backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.3) 100%), url(${cover})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '150vh' }}>
                <div className="row gx-0 card-body" style={{ justifyContent: "center" }}>
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12" style={{ position: 'absolute', top: '200px', height: '100vh' }}>
                        <form className=" dz-form pb-3" style={{ lineHeight: '30px' }} onSubmit={AddMT4Id}>
                            <h3 className="form-title m-t0" style={{ textAlign: 'center', color: 'white' }}>Register your MT4 ID and use our Forex BOT</h3>
                            <div className="dz-separator-outer m-b5">
                                <div className="dz-separator bg-primary style-liner"></div>
                            </div>
                            <div className="form-group mb-3">
                                <input type="acnumber" className="form-control" placeholder="Enter MT4 Account Number" value={acnumber} onChange={(e) => setAcnumber(e.target.value)} />
                                {errors.acnumber && <div className="text-danger fs-12">{errors.acnumber}</div>}
                            </div>
                            <div className="form-group text-left mb-5">
                                <div style={{ textAlign: 'center' }}><button type="submit" className="btn btn-primary dz-xs-flex m-r5">Submit</button></div>
                            </div>
                        </form>
                        <div style={{ paddingBottom: '30px' }}>
                            <h2>Steps to start the Bot</h2>
                            <p>1. Open MT4 and click on Tools</p>
                            <p>2. Click on Options</p>
                            <p>3. Go to Expert Advicer</p>
                            <p>4. Click/Enable "Allow WebRequest for listed URL" </p>
                            <p>5. Add this URL in the below table "https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/check-mt4-loginid"</p>
                            <p>6. Start the Infinity Trader Bot by double clicking on it</p>

                        </div>
                    </div>
                </div>

            </div>

        </>

    )
};

export default Link_mt4_id;