import React, { useState } from 'react';

const ImagePopup = ({ imageUrl, onClose }) => {
    const [showModal, setShowModal] = useState(true);

    const closeModal = () => {
        setShowModal(false);
        onClose();
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };


    return (
        <>
            {showModal && (
                <div className="image-modal-overlay" onClick={handleOverlayClick}>
                    <div className="image-modal-content">
                        <img src={imageUrl} alt="Full size" />
                        <button onClick={closeModal}>X</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImagePopup;
