import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import upi from '../../assets/images/course/upi-icon.png'
import gpay from '../../assets/images/course/google-pay.png'
import phonepe from '../../assets/images/course/phonepe.png'
import paytm from '../../assets/images/course/paytm.png'
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
const SlotBooking = () => {

    const location = useLocation();
    const { state } = location; // Access the entire state object passed from the previous component
    // Check if state exists and contains courseAmount
    const courseAmount = state?.courseAmount;
    const modulename = state?.module;
    const [paymenturl, setPaymenturl] = useState(null);
    const [gpaypaymenturl, setGpayPaymenturl] = useState(null);
    const [phonepementurl, setPhonepePaymenturl] = useState(null);
    const [paytmpaymenturl, setPaytmPaymenturl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [sendingmessage, setSendingmessage] = useState(false);
    const [upiId, setUpiId] = useState('');

    const [copied, setCopied] = useState(false);
    const [copyClicked, setCopyClicked] = useState(false);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            var messagestaus = document.getElementById('messagestaus');
            messagestaus.innerText = '';
            // Check if the file is an image (MIME types: image/jpeg, image / png, etc.)
            const acceptedImageTypes = [
                'image/jpeg',
                'image/png',
                'image/bmp',
                'image/webp',
                'image/tiff',
            ];
            if (!acceptedImageTypes.includes(file.type)) {
                // Handle the case where the selected file is not an image (optional)
                console.log('Please select only image file.');
                messagestaus.innerText = 'Please select only image file.';
                messagestaus.style = "color: red"
                return;
            }

            // Check if the file size is below 5MB
            const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
            if (file.size > maxSizeInBytes) {
                // Handle the case where the file size exceeds the limit (optional)
                console.log('File size should be below 5MB.');
                messagestaus.innerText = 'File size should be below 5MB.';
                messagestaus.style = "color: red"
                return;
            }
        }
        setSelectedFile(file);
    };

    const navigate = useNavigate();
    const [transactionId, SetTransactionId] = useState('');
    const [telegramid, SetTelegramid] = useState('');
    let errorsObj = { transactionId: '', screenshot: '' };
    const [errors, setErrors] = useState(errorsObj);
    // const dispatch = useDispatch();

    const userid = localStorage.getItem('userid');
    // Automatic redirection to home page if user hasUserId and accesses login page
    useEffect(() => {
        if (!userid) {
            alert('Please login to continue.')
            navigate('/login');
        }
    }, [userid, navigate]);

    function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (transactionId === '') {
            errorObj.transactionId = 'TransactionId is Required';
            error = true;
        }
        if (selectedFile === null) {
            errorObj.screenshot = 'Payment Screenshot is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        } else {
            setSendingmessage(true)
            var messagestaus = document.getElementById('messagestaus');
            messagestaus.innerText = '';
            messagestaus.style = "color: red"
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend = async () => {
                const fileData = reader.result.split(',')[1]; // Remove the data:image/jpeg;base64, prefix

                // Prepare the data as a JSON object
                const data = {
                    uuid: localStorage.getItem('uuid'),
                    module: modulename,
                    transactionid: transactionId,
                    telegramid: telegramid,
                    screenshotfilename: selectedFile.name,
                    screenshot: fileData, // Include the file data as a Base64 string
                };
                axios.post('https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/save-transactions', data)
                    .then((response) => {
                        if (!response.ok) {
                            messagestaus.innerText = response.status;
                        }
                        return response.data;
                    })
                    .then(data => {
                        if (data) {
                            SetTransactionId('');
                            setSelectedFile(null);
                            SetTelegramid('')
                            setSendingmessage(false)
                            alert(data.status);
                            messagestaus.innerText = data.status;
                            messagestaus.style = "color: #26d53e"
                        }
                    }).catch(error => {
                        setSendingmessage(false)
                        messagestaus.innerText = error.response.data.status;
                        messagestaus.style = "color: red"
                        setTimeout(() => {
                            messagestaus.innerText = '';
                            messagestaus.style = "color: red"
                        }, 5000);// Error saving message // Error saving message
                    })
            }
        }
    }

    useEffect(() => {
        var tn = '';
        var pa = '';
        if (modulename === "class") {
            setUpiId('arundoss2024@okhdfcbank')
            tn = 'Live Class';
            pa = 'arundoss2024@okhdfcbank';
        } else if (modulename === "forexbot") {
            setUpiId('arundoss2024@okhdfcbank')
            tn = 'Forex Automated system';
            pa = 'arundoss2024@okhdfcbank';
        } else if (modulename === "Binarysignals") {
            setUpiId('dineshdina38@okicici')
            tn = 'Binary Signals';
            pa = 'dineshdina38@okicici';
        }

        const paymentData = {
            pa: pa,
            pn: 'InfinityTrader',
            mc: '1234',
            tr: '1234',
            tn: tn,
            am: courseAmount,
            cu: 'INR',
        };

        // Convert the payment data into a query string
        setPaymenturl('upi://pay?' + queryString.stringify(paymentData));
        setGpayPaymenturl('tez://upi/pay?' + queryString.stringify(paymentData));
        setPhonepePaymenturl('phonepe://pay?' + queryString.stringify(paymentData));
        setPaytmPaymenturl('paytmmp://pay?' + queryString.stringify(paymentData));
    }, [courseAmount, modulename]);

    function sendPayment(e) {
        window.open(paymenturl, '_blank');
    };

    const openPaymentApp = (payApp) => {
        var appurl = null;
        switch (payApp) {
            case 'PAYTM': appurl = paytmpaymenturl; break;
            case 'GPAY': appurl = gpaypaymenturl; break;
            case 'PHONEPE': appurl = phonepementurl; break;
            default: appurl = paymenturl; break;
        }
        window.open(appurl, '_blank');
    };


    const handleCopyClick = () => {
        setCopyClicked(true);
        navigator.clipboard.writeText(upiId)
            .then(() => setCopied(true))
            .catch((error) => console.error('Failed to copy: ', error));
        setTimeout(() => {
            setCopyClicked(false);
        });

    };

    return (
        < div className='slotbooking'>
            <div className="container">
                <div className=" style-1 text-center">
                    <div className="section-head " >
                        <h4 className="title m-t0">Make payment</h4>
                    </div>
                    <div className="form-wrapper">
                        <div className="flex-1">
                            <div className="row g-3">
                                <div >
                                    <h5>Step - 1</h5>
                                    {isMobile ?
                                        <div><li>Use UPI method to make the payment, Click on the UPI button to proccess your payment using any UPI apps </li></div>
                                        : <div><li>Use UPI method to make the payment using below QR code by scanning with any UPI apps</li></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        // (isMobile && modulename === "Binarysignals")
                        //     ?
                        //     <div>
                        //         <button style={{ margin: '10px 10px', backgroundColor: '#fff', height: '50px', marginTop: '20px', width: '125px' }} className='btn' onClick={sendPayment}><img src={upi} alt=''></img></button>
                        //         <button style={{ margin: '10px 10px', backgroundColor: '#fff', height: '50px', marginTop: '20px', width: '125px', padding: '23px' }} className='btn' onClick={() => openPaymentApp('GPAY')}><img src={gpay} alt=''></img></button>

                        //         <button style={{ margin: '10px 10px', backgroundColor: '#fff', height: '50px', marginTop: '20px', width: '125px' }} className='btn' onClick={() => openPaymentApp('PHONEPE')}><img src={phonepe} alt=''></img></button>
                        //         <button style={{ margin: '10px 10px', backgroundColor: '#fff', height: '50px', marginTop: '20px', width: '125px', padding: '23px' }} className='btn' onClick={() => openPaymentApp('PAYTM')}><img src={paytm} alt=''></img></button>

                        //     </div>
                        //     :
                        <>
                            <span style={{ color: '#fff' }}><strong>UPI-ID : </strong>{upiId}
                                <span onClick={handleCopyClick}>
                                    <i class={copied ? "fa fa-check" : "far fa-copy"} style={{ cursor: 'pointer', color: copyClicked && '#000', paddingLeft: '5px', fontSize: '11px' }} ></i>
                                </span>
                            </span>
                            <br></br>
                            <span style={{ color: '#fff' }}><strong>Amount : </strong>{courseAmount}</span>
                            <div className='qrcode'>
                                <QRCode value={paymenturl} />
                            </div>
                        </>
                    }
                    <div style={{ margin: '20px 0px' }}>
                        <h5>Step - 2</h5>
                        <div><li>After payment successfull, Attach the screenshot and Transaction ID and submit.</li></div>
                    </div>
                </div>
            </div >




            <section>
                <div className="container">
                    <div className="row about-bx2 style-1 align-items-center">
                        <div className="row gx-0" style={{ justifyContent: "center", padding: '0px 20px' }}>
                            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                                <form className=" dz-form pb-3" style={{ lineHeight: '30px' }} onSubmit={onLogin}>
                                    <h5 className="form-title m-t0" style={{ textAlign: 'center', color: 'white' }}>Submit your Payment details</h5>
                                    <div className="dz-separator-outer m-b5">
                                        <div className="dz-separator bg-primary style-liner"></div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" placeholder="Enter your transaction ID" value={transactionId} onChange={(e) => SetTransactionId(e.target.value)} />
                                        {errors.transactionId && <div className="text-danger fs-12">{errors.transactionId}</div>}
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" placeholder="telegramid  (optional)" value={telegramid} onChange={(e) => SetTelegramid(e.target.value)} />
                                        {errors.telegramid && <div className="text-danger fs-12">{errors.telegramid}</div>}
                                    </div>

                                    <div className="form-group mb-3">
                                        {selectedFile ? selectedFile.name : 'Attach your Screenshot'}
                                        <label htmlFor="fileInput" className=' chatAttachment'>


                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileSelect}
                                                style={{ display: 'none' }}
                                                id="fileInput"
                                            />
                                            <i className="fa fa-paperclip" style={{ fontSize: '20px', padding: '5px 5px 5px 5px', color: '#d3d3d3' }}></i>

                                        </label>
                                        {selectedFile &&
                                            <><i className="fa fa-trash-o" aria-hidden="true" style={{ fontSize: '17px', color: 'red', cursor: 'pointer' }} onClick={(e) => setSelectedFile(null)}></i>
                                                <div><img src={URL.createObjectURL(selectedFile)} alt="Selected" /></div>
                                            </>
                                        }
                                        {errors.screenshot && <div className="text-danger fs-12">{errors.screenshot}</div>}
                                        <div id="messagestaus"></div>
                                    </div>
                                    <div className="form-group" style={{ textAlign: 'center' }}>
                                        {sendingmessage
                                            ? <div style={{ textAlign: 'center' }} className="btn btn-primary"> <i className="fa fa-spinner fa-spin" style={{ fontSize: '17px', color: '#2c0191' }}></i></div>
                                            : <div style={{ textAlign: 'center' }}><button type="submit" className="btn btn-primary dz-xs-flex m-r5">Submit</button></div>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className=" style-1 text-center">
                    <div style={{ marginBottom: '20px' }}>
                        <h5>Step - 3</h5>
                        <div><li>After submitting the paymemt details above, you will be receiving email with payment status</li></div>
                        <div><li>Your payment status will be validated with in 24 hours</li></div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default SlotBooking;