import React from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import PageLayout from '../layouts/PageLayout';
//images
// import pic1 from './../assets/images/blog/pic1.jpg';
// import pic2 from './../assets/images/blog/pic2.jpg';
// import pic3 from './../assets/images/blog/pic3.jpg';
// import pic4 from './../assets/images/blog/pic4.jpg';
// import pic5 from './../assets/images/blog/pic5.jpg';
// import pic6 from './../assets/images/blog/pic6.jpg';
import BinaryPricing from '../components/Binary/BinaryPricing';

// import CountdownTimer from '../components/General/CountdownTimer'

const gridData = [
    { asset: "Crypto IDX", title: 'Signals That Make You Love Crypto' },
    { asset: "EUR-USD", title: '7 Ingenious Ways You Can Do With InfinityTrader' },
    { asset: "AUD-USD", title: '14 Days To A Better InfinityTrader ' },
    { asset: "EUR-MXN", title: 'Why You Should Not Go To InfinityTrader.' },
    { asset: "EUR-JPY", title: 'Five Easy Rules Of bitcoin.' },
    { asset: "CHF-JPY", title: '14 Days To A Better InfinityTrader .' },
    { asset: "AUD-JPY", title: 'Why You Should Not Go To InfinityTrader.' },
    { asset: "USD-JPY", title: 'Five Easy Rules Of bitcoin.' },
    { asset: "USD-CHF", title: '14 Days To A Better InfinityTrader .' },

    { asset: "EUR IDX", title: '14 Days To A Better InfinityTrader .' },
    { asset: "EUR-NZD", title: '14 Days To A Better InfinityTrader .' },
    { asset: "USD-CAD", title: '14 Days To A Better InfinityTrader .' },
];
// const courseEndDate = new Date('2023-11-13');

const BinarySignals = () => {
    return (
        <>
            <Helmet>
                <title>Infinity Trader - BinarySignals</title>
            </Helmet>
            <div className="page-content">
                <PageLayout pageTitle="Binary Signals" />
                <section className="content-inner" style={{ marginTop: '30px', marginBottom: '20px' }}>
                    <div className="container">
                        <div className="row">
                            {gridData.map((item, ind) => (
                                <div className="col-md-6 col-xl-4 m-b30" key={ind}>
                                    <div className="dz-card style-1 blog-lg overlay-shine assetbg">
                                        {/* <div className="dz-media">
                                            <Link to={"/signals"}><img src={item.image1} alt="" /></Link>
                                        </div> */}
                                        <div className="dz-info">
                                            <h4 className="dz-title">{item.asset}</h4>
                                            <Link to={"/signals?asset=" + item.asset} className="btn btn-primary">Check Signals</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>
                </section>
                {/* {<div style={{ marginTop: '20px' }}><h4 className='dull-white-title'>Offer will end soon</h4><CountdownTimer endDate={courseEndDate} /> </div>} */}
                <BinaryPricing />
            </div>
        </>
    )
}
export default BinarySignals;