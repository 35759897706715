import React from 'react';
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevronDown from '../../assets/svg/chevron-down.svg';

const AccordionItem = ({ header, content }) => (
    <Item
        header={
            <>
                {header}
                <img className={'chevron'} src={chevronDown} alt="Chevron Down" />
            </>
        }
        className={'item'}
        buttonProps={{
            className: ({ isEnter }) => `${'itemBtn dull-white-title'} ${isEnter && 'itemBtnExpanded'}`
        }}
        contentProps={{ className: 'itemContent' }}
        panelProps={{ className: 'itemPanel' }}
    >
        {content}
    </Item>
);

const AccordionComponent = ({ data }) => {
    return (
        <div>
            <div className='accordion'>
                <Accordion transition transitionTimeout={250}>
                    {data.map((item, index) => (
                        <AccordionItem key={index} header={item.header} content={item.content} />
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default AccordionComponent;
