import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const Pricing = (courseEndDate) => {

    const [originalcourseAmount, setOriginalCourseAmount] = useState('9999');
    const [courseAmount, setCourseAmount] = useState('9999');
    const [offerApplied, setOfferApplied] = useState(false);
    const [copied, setCopied] = useState(false);
    const [copyClicked, setCopyClicked] = useState(false);
    const [usercoupon, setUsercoupon] = useState();
    const [couponCode, setOriginalcoupon] = useState('INFI-GOLD');

    const Availoffer = () => {
        var couponstaus = document.getElementById('couponstaus');
        if (!usercoupon) {
            couponstaus.style = "color: red"
            couponstaus.innerText = 'Enter coupon code';
            setOfferApplied(false);
        }
        if (!offerApplied && usercoupon) {
            if (usercoupon === couponCode) {
                setOfferApplied(true);
                setCourseAmount(parseFloat(courseAmount - (courseAmount / 100) * 30).toFixed());
                couponstaus.style = "color: green"
                couponstaus.innerText = 'Greate! Discount applied.';
            } else {
                couponstaus.style = "color: red"
                couponstaus.innerText = 'Oops! Invalid coupon code';
                setOfferApplied(false);
            }
        }

    }
    const Removeoffer = () => {
        setOfferApplied(false);
        setCourseAmount(originalcourseAmount);
        setUsercoupon('');
        var couponstaus = document.getElementById('couponstaus');
        couponstaus.innerText = '';
    }

    const handleCopyClick = () => {
        setCopyClicked(true);
        navigator.clipboard.writeText(couponCode)
            .then(() => setCopied(true))
            .catch((error) => console.error('Failed to copy: ', error));
        setTimeout(() => {
            setCopyClicked(false);
        });

    };

    const pricingBlog = [
        {
            price: '1000',
            title: 'Lite',
            title2: 'Life-includes:',
            content: 'Book your slot and upgrade your skills in trading.' // Replace with actual course content
        }
    ];
    const navigate = useNavigate();
    const sendPayment = async () => {
        navigate('/slot-booking', { state: { courseAmount: courseAmount, module: 'class' } });
    };

    return (
        < div className='course-price-containser' >
            {pricingBlog.map((data, index) => (
                <div className="col-xl-4 col-lg-6 col-md-6 m-b30" key={index}>
                    <div className={`pricingtable-wrapper box-hover style-1`}>
                        <div className="pricingtable-inner bg-gradient">
                            {data.content}
                            <h3 className="pricingtable-title">
                                Trading Luminary
                            </h3>
                            <div className="pricingtable-price">
                                <h2 className="pricingtable-bx text-primary">{offerApplied && <s className='original-price-strike'>₹{originalcourseAmount}</s>} ₹{parseInt(courseAmount).toFixed(0)}</h2>
                                {courseEndDate ? <div className="btn btn-primary bookslot" onClick={sendPayment}>Book your seat</div> : null}

                                <h6>Course overview</h6>
                                <div>
                                    <li>All Basic concepts</li>
                                    <li>All Advanced concepts</li>
                                    <li>Live Interaction and Doubt Clarifications</li>
                                </div>
                                {/* <div>
                                    <img src={discount1} alt></img>
                                    <div className='coupon-code'>
                                        INFI-GOLD
                                        <span onClick={handleCopyClick}>
                                            <i class="far fa-copy" style={{ cursor: 'pointer', color: copyClicked && '#000', paddingLeft: '5px' }} ></i>
                                        </span>
                                    </div>
                                </div> */}
                                <div>
                                    <input value={usercoupon} onChange={(e) => setUsercoupon(e.target.value.trim())} type='text' className="coupon" placeholder="Coupon code" />
                                </div>
                                <div id="couponstaus"></div>
                                {offerApplied ?
                                    <div className='remove-offer' onClick={Removeoffer}><span>Remove Coupon</span></div>
                                    :
                                    <div className='apply-offer' onClick={Availoffer}><span>Apply Coupon</span></div>}

                            </div>
                            <div className='offer-tag'>30% off</div>
                        </div>
                    </div>

                </div>
            ))
            }

        </div >
    )
}

export default Pricing;