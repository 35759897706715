import React, { useRef, useEffect, useState } from 'react';

import PlayButton from './PlayButton/PlayButton';
import PropTypes from 'prop-types';
import Seeker from './Seeker/Seeker'

const Audioplayer = ({ url, fileduration, isPlaying, onPlayButtonClick }) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);

    const handleTrackClick = (position) => {
        audioRef.current.currentTime = position;
    };

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [audioRef, isPlaying]);

    useEffect(() => {
        audioRef.current.volume = 1; // Set the initial volume here (if needed)
    }, []);

    const handleLoadedMetadata = () => {
        const [minutes, seconds] = fileduration.split(":").map(Number);
        setDuration((minutes * 60 + seconds));
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    return (
        <div className="audio-player" style={{ display: 'flex' }}>
            <PlayButton isPlaying={isPlaying} setIsPlaying={onPlayButtonClick} audioRef={audioRef} />
            <Seeker isPlaying={isPlaying} currentTime={currentTime} duration={duration} handleTrackClick={handleTrackClick} />
            <audio
                ref={audioRef}
                src={url}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
            />
        </div>
    );
};

Audioplayer.propTypes = {
    url: PropTypes.string.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    onPlayButtonClick: PropTypes.func.isRequired,
};

export default Audioplayer;




