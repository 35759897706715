import React, { useState } from 'react';
// import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
// import {
//     loadingToggleAction, loginAction,
// } from '../../store/actions/AuthActions';


import bg6 from '../../assets/images/background/bg6.jpg';

const ResetPassword = (props) => {
    const [statustext, setStatustext] = useState('');
    const [statuscolor, setStatuscolor] = useState('text-danger');
    let errorsObj = { password: '', confirmpassword: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');

    const [status, setStatus] = useState(false);

    function onResetPassword(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        setStatuscolor('text-danger');
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        if (confirmpassword === '') {
            errorObj.confirmpassword = 'Confirm Password is Required';
            error = true;
        }
        if (confirmpassword !== password) {
            errorObj.confirmpassword = 'Password and Confirm Password is not same';
            error = true;
        }
        if (password.length < 5) {
            errorObj.password = 'Password should be atleast 5 characters.';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        } else {
            // Get the URL parameters
            const urlParams = new URLSearchParams(window.location.search);

            // Get the token value from the 'token' parameter
            const token = urlParams.get('token');
            // Create the request body
            var requestBody = {
                token: token,
                password: password,
                confirmpassword: confirmpassword
            };

            // Make the API request
            fetch("https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/reset-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    if (response.status === 200) {
                        setStatus(true);
                        setStatuscolor('text-success')
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.status) {
                        setStatus(true);
                        setStatustext(data.status);
                    }
                })
                .catch(error => {
                    // Handle any errors
                    console.error(error);
                });
        }
    }


    return (
        <div className="browse-job login-style3">
            <div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh" }}>
                <div className="row gx-0" style={{ justifyContent: "center" }}>
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                        <div id="mCSB_1" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style={{ maxHeight: "653px" }}>
                            <div id="mCSB_1_container" className="mCSB_container" style={{ position: "relative", marginTop: "100px", left: "0", dir: "ltr" }}>
                                <div className="login-form style-2">
                                    <div className="card-body">
                                        {/* <div className="logo-header">
                                            <Link to={"#"} className="logo"><img src={logo} alt="" className="width-230 mCS_img_loaded" /></Link>
                                        </div> */}
                                        <div className="nav nav-tabs border-bottom-0" >
                                            <div className="tab-content w-100" id="nav-tabContent">
                                                <div className="tab-pane fade active show" id="nav-personal">
                                                    {props.errorMessage && (
                                                        <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                                            {props.errorMessage}
                                                        </div>
                                                    )}
                                                    {props.successMessage && (
                                                        <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                                            {props.successMessage}
                                                        </div>
                                                    )}
                                                    <form className=" dz-form pb-3" style={{ lineHeight: '30px' }} onSubmit={onResetPassword}>
                                                        <h3 className="form-title m-t0" style={{ textAlign: 'center', color: 'white' }}>Reset Password</h3>
                                                        <div className="dz-separator-outer m-b5">
                                                            <div className="dz-separator bg-primary style-liner"></div>
                                                        </div>
                                                        <p>Enter your new password. </p>
                                                        <div className="form-group mb-3">
                                                            <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                            {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <input type="text" className="form-control" placeholder="Confirm Password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                            {errors.confirmpassword && <div className="text-danger fs-12">{errors.confirmpassword}</div>}
                                                        </div>
                                                        <div>
                                                            {status && <div className={`fs-12 ${statuscolor}`}>{statustext}</div>}
                                                        </div>
                                                        <div className="form-group text-left mb-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <button type="submit" className="btn btn-primary dz-xs-flex m-r5">Submit</button>
                                                            <NavLink to="/login" className="btn btn-primary " >
                                                                Back
                                                            </NavLink>
                                                        </div>
                                                        {/* <div className="dz-social">
                                                            <h5 className="form-title fs-20">Sign In With</h5>
                                                            <ul className="dz-social-icon dz-border dz-social-icon-lg text-white">
                                                                <li><a target="_blank" href="https://t.me/Infinitytradersss" className="fab fa-facebook-f btn-facebook"></a></li>
                                                                <li><a target="_blank" href="https://www.google.com/" className="fab fa-google-plus-g btn-google-plus"></a></li>
                                                                <li><a target="_blank" href="https://www.linkedin.com/" className="fab fa-linkedin-in btn-linkedin"></a></li>
                                                                <li><a target="_blank" href="https://twitter.com/" className="fab fa-twitter btn-twitter"></a></li>
                                                            </ul>
                                                        </div> */}
                                                    </form>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResetPassword;