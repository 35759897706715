import React from 'react';
import PropTypes from 'prop-types';

import convertTime from '../Audio/ConvertTime';
import Range from './Range';

import stylesheet from './Seeker.module.css';


function Seeker({ isPlaying, currentTime, duration, handleTrackClick }) {
    return (
        <div className={stylesheet.seeker}>

            <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <span style={{ fontSize: '9px' }} className={stylesheet.time}>{convertTime(currentTime)}</span>
                <span style={{ fontSize: '9px' }} className={stylesheet.time}>{convertTime(duration)}</span>
            </div>
            <div style={{ marginTop: '-7px' }}>
                <Range
                    max={duration}
                    value={currentTime}
                    handleChange={handleTrackClick}
                />
            </div>
        </div>
    );
}

Seeker.propTypes = {
    currentTime: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    handleTrackClick: PropTypes.func.isRequired,
};

export default Seeker;