import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from './../layouts/ScrollToTop';
// import Header from './../layouts/Header';
// import Footer from './../layouts/Footer';
import Home from './Home';
import AboutUs from './AboutUs';
import Pricing from './Pricing';
import ContactUs from './ContactUs';
import BinaryBot from './BinaryBot';
import BinarySignals from './BinarySignals';
import Signals from './Signals';
import UserLiveChat from './LiveChat/UserLiveChat';
import Login from './Login';
import Register from './Register';
import Layout from '../components/Layout';
import HeaderLayout from '../components/HeaderLayout';
import ForgetPassword from './PasswordUpdate/ForgetPassword';
import ResetPassword from './PasswordUpdate/ResetPassword';
import ForexCopyTrade from './Forex/ForexCopyTrade';
import ForexSignal from './Forex/ForexSignal';

import ForexBot from './Forex/ForexBot';
import Link_mt4_id from './Forex/Link_mt4_id';
import Technicals from './Forex/Technicals';

import LiveClass from './Courses/LiveClass';
import SlotBooking from './Courses/SlotBooking';
import FreeTutorial from './Learnings/FreeTutorial';
import TandC from './TandC';

import Discord from './DarkWorld/Discord';

const HomeComponent = Layout(Home);
const AboutUsComponent = Layout(AboutUs);
const PricingComponent = Layout(Pricing);
const ContactUsComponent = Layout(ContactUs);
const BinaryBotComponent = Layout(BinaryBot);
const BinarySignalsComponent = Layout(BinarySignals);
const SignalsComponent = Layout(Signals);
const ForexBotComponent = Layout(ForexBot);
const ForexCopyTradeComponent = HeaderLayout(ForexCopyTrade);
const ForexSignalComponent = Layout(ForexSignal);

const TandCComponent = Layout(TandC);

const LoginComponent = HeaderLayout(Login);
const RegisterComponent = HeaderLayout(Register);
const ForgetPasswordComponent = HeaderLayout(ForgetPassword)
const ResetPasswordComponent = HeaderLayout(ResetPassword)
const UserLiveChatComponent = HeaderLayout(UserLiveChat)
const TechnicalsComponent = HeaderLayout(Technicals)
const LiveClassComponent = HeaderLayout(LiveClass)
const SlotBookingComponent = HeaderLayout(SlotBooking)
const FreeTutorialComponent = HeaderLayout(FreeTutorial)

const Link_mt4_idComponent = HeaderLayout(Link_mt4_id);

const HomeComponentWrapper = () => <HomeComponent />;
const Index = () => {
	return (
		<BrowserRouter>
			<div className="page-wraper">
				{/* <Header /> */}
				<Routes>
					<Route path="*" element={<HomeComponentWrapper />} />
					<Route path='/about-us' exact element={<AboutUsComponent />} />
					<Route path='/pricing' exact element={<PricingComponent />} />
					<Route path='/contact-us' exact element={<ContactUsComponent />} />
					<Route path='/binary-bot' exact element={<BinaryBotComponent />} />
					<Route path='/binary-signals' exact element={<BinarySignalsComponent />} />
					<Route path='/signals' element={<SignalsComponent />} />


					<Route path='/forex-copy-trade' element={<ForexCopyTradeComponent />} />
					<Route path='/forex-bot' element={<ForexBotComponent />} />
					<Route path='/forex-signal' element={<ForexSignalComponent />} />
					<Route path='/technicals' element={<TechnicalsComponent />} />

					<Route path='/user-live-chat' element={<UserLiveChatComponent />} />
					<Route path='/login' exact element={<LoginComponent />} />
					<Route path='/register' exact element={<RegisterComponent />} />
					<Route path='/forget-password' exact element={<ForgetPasswordComponent />} />
					<Route path='/reset-password' exact element={<ResetPasswordComponent />} />
					<Route path='/courses' element={<LiveClassComponent />} />
					<Route path='/slot-booking' element={<SlotBookingComponent />} />
					<Route path='/free-tutorials' element={<FreeTutorialComponent />} />
					<Route path='/terms-and-conditions' element={<TandCComponent />} />
					<Route path='/discord' element={<Discord />} />

					<Route path='/link-mt4-id' exact element={<Link_mt4_idComponent />} />



				</Routes>
				{/* <Footer /> */}
				<ScrollToTop />
			</div>
		</BrowserRouter>
	)
}
export default Index;