import React from 'react';

const Technicals = () => {
    // const iframesData = [
    //     {
    //         iframe: '',
    //         content: 'Content for Iframe 1 goes here.',
    //     },
    //     {
    //         id: 2,
    //         src: 'https://www.example.com/iframe2',
    //         title: 'Iframe 2',
    //         content: 'Content for Iframe 2 goes here.',
    //     },
    //     // Add more iframe data as needed
    // ];
    return (
        <>
            <div className="page-content">
                {/* <PageLayout desc={false} pageTitle={"Live Chat"} /> */}
                <section className="content-inner" >
                    <div className="container">
                        <div className="row " >
                            <div className="iframe-table-container" style={{ marginTop: '100px', textAlign: 'center', }} >


                                <div>
                                    <h4>Predictions</h4>
                                    <div style={{ overflow: 'auto' }}>
                                        <iframe title='Predictions' frameborder="0" scrolling="no" height="274" width="425" allowtransparency="true" marginwidth="0" marginheight="0" src="https://ssltools.investing.com/technical_summary.php?pairs=&curr-name-color=%230059B0&fields=5m,15m,1h&force_lang=56"></iframe>
                                    </div>
                                </div>
                                <div>
                                    <h4>Exchange Rate Table</h4>
                                    <div style={{ overflow: 'auto' }}>
                                        <iframe title='Exchange' frameborder="0" scrolling="auto" height="175" width="555" allowtransparency="true" marginwidth="0" marginheight="0" src="https://sslfxrates.investing.com/index_exchange.php?params&inner-border-color=%23CBCBCB&border-color=%23cbcbcb&bg1=%23000000&bg2=%23000000&inner-text-color=%23ffffff&currency-name-color=%23000000&header-text-color=%23FFFFFF&header-bg=%235e5e5e&force_lang=56" align="center"></iframe>
                                    </div>
                                </div>
                                <div>
                                    <h4>Margin Calculator</h4>
                                    <div style={{ overflow: 'auto' }}>
                                        <iframe title='Margin' frameborder="0" scrolling="no" height="425" width="430" allowtransparency="true" marginwidth="0" marginheight="0" src="https://ssltools.investing.com/margin-calculator/index.php?force_lang=56&acc=12&pair=1"></iframe>
                                    </div>
                                </div>
                                <div>
                                    <h4>Fibonacci Calculator</h4>
                                    <div style={{ overflow: 'auto' }}>
                                        <iframe title='Fibonacci' frameborder="0" scrolling="auto" height="650" width="545" allowtransparency="true" marginwidth="0" marginheight="0" src="https://ssltools.investing.com/fibonacci-calculator/index.php?force_lang=56"></iframe>
                                    </div>
                                </div>
                                <div>
                                    <h4>Pip Calculator</h4>
                                    <div style={{ overflow: 'auto' }}>
                                        <iframe title='Pip' frameborder="0" scrolling="auto" height="640" width="545" allowtransparency="true" marginwidth="0" marginheight="0" src="https://ssltools.investing.com/pip-calculator/index.php?force_lang=56&acc=12"></iframe>
                                    </div>
                                </div>
                                <div>
                                    <h4>Forex Pivot Point Calculator</h4>
                                    <div style={{ overflow: 'auto' }}>
                                        <iframe title='Pivot' frameborder="0" scrolling="auto" height="650" width="543" allowtransparency="true" marginwidth="0" marginheight="0" src="https://ssltools.investing.com/pivot-calculator/index.php?force_lang=56"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Technicals;