import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import axios from 'axios';
import ChatMessages from '../../components/Chatbox/ChatMessages';
// import data from '@emoji-mart/data'
// import Picker from '@emoji-mart/react';
import { Helmet } from 'react-helmet';


const UserLiveChat = () => {
    const [inputValue, setInputValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [totalmessages, setTotalmessages] = useState();
    const [messages, setMessages] = React.useState({});

    const [recording, setRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const [audioData, setAudioData] = useState(null);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    // const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [sendingmessage, setSendingmessage] = useState(false);

    const audioRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const messageInputRef = useRef(null);
    const Timing = useRef(0);

    var adminName = "Infinity Trader";
    const uuid = localStorage.getItem('uuid');
    const navigate = useNavigate();
    useEffect(() => {
        if (!uuid) {
            navigate('/');
        }
    }, [uuid, navigate]);
    useEffect(() => {
        if (recording) {
            const interval = setInterval(() => {
                Timing.current = Timing.current + 1;
                setTimer((prevTimer) => {
                    return prevTimer + 1;
                });

            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [recording]);

    const handleMicrophoneButtonClick = () => {
        if (recording) {
            stopRecording();
            setAudioData(null);
            setShowDeleteButton(false);
        } else {
            startRecording();

        }
    };

    const startRecording = async () => {
        const audioChunks = [];
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStreamRef.current = mediaStream;
            const mimeType = 'audio/webm';
            const mediaRecorder = new MediaRecorder(mediaStream, { mimeType });
            mediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            mediaRecorder.start();

            mediaRecorder.onstop = () => {
                setRecording(false);
                const audioBlob = new Blob(audioChunks, { type: mimeType });
                setAudioData(audioBlob);
                submitAudio(audioBlob);
            };
            // Assign the mediaRecorder directly to audioRef.current
            audioRef.current = {
                srcObject: mediaStream,
                mediaRecorder: mediaRecorder,
            };
            setTimer(0);
            Timing.current = 0;
            setRecording(true);
            setShowDeleteButton(true);
        } catch (error) {
            setRecording(false);
        }
    };

    const stopRecording = async () => {
        if (audioRef.current?.mediaRecorder && audioRef.current.mediaRecorder.state !== 'inactive') {
            audioRef.current.mediaRecorder.stop();
        }
        if (mediaStreamRef.current) {
            const mediaTracks = mediaStreamRef.current.getTracks();
            mediaTracks.forEach((track) => track.stop());
            mediaStreamRef.current = null;
        }
    };

    const DeleteRecording = () => {
        setRecording(false);
        setShowDeleteButton(false);
        setAudioData(null);
        if (audioRef.current?.mediaRecorder && audioRef.current.mediaRecorder.state !== 'inactive') {
            audioRef.current.mediaRecorder.stop();
        }
        if (mediaStreamRef.current) {
            const mediaTracks = mediaStreamRef.current.getTracks();
            mediaTracks.forEach((track) => track.stop());
            mediaStreamRef.current = null;
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        var messagestaus = document.getElementById('messagestaus');
        messagestaus.innerText = '';
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            var messagestaus = document.getElementById('messagestaus');
            messagestaus.innerText = '';
            // Check if the file is an image (MIME types: image/jpeg, image / png, etc.)
            const acceptedImageTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/bmp',
                'image/webp',
                'image/tiff',
                'image/svg+xml', // SVG images (XML-based vector format)
            ];
            if (!acceptedImageTypes.includes(file.type)) {
                messagestaus.innerText = 'Please select only image file.';
                messagestaus.style = "color: red"
                return;
            }

            // Check if the file size is below 5MB
            const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
            if (file.size > maxSizeInBytes) {
                // Handle the case where the file size exceeds the limit (optional)
                messagestaus.innerText = 'File size should be below 5MB.';
                messagestaus.style = "color: red"
                return;
            }
        }
        setSelectedFile(file);
    };

    // const handleToggleEmojiPicker = () => {
    //     setShowEmojiPicker((prevShowEmojiPicker) => !prevShowEmojiPicker);
    // };

    // const handleEmojiSelect = (emoji) => {
    //     setInputValue((prevInputValue) => prevInputValue + emoji.native);
    // };

    var messageWaitTime;
    const app = initializeApp({ projectId: "infinitytrader-f65ff" });
    const database = getDatabase(app);
    const messagesRef = ref(database, 'messages');

    const sendMessage = (event) => {
        event.preventDefault()
        var messagestaus = document.getElementById('messagestaus');
        const message = inputValue.trim();
        const uuid = localStorage.getItem('uuid');
        if (uuid) {
            if (message !== '') {
                if (CheckMessageTiming()) {

                    setSendingmessage(true)
                    messagestaus.innerText = '';
                    // Read the file and convert it to a Base64 string
                    if (selectedFile) {
                        const reader = new FileReader();
                        reader.readAsDataURL(selectedFile);
                        reader.onloadend = async () => {
                            const fileData = reader.result.split(',')[1]; // Remove the data:image/jpeg;base64, prefix

                            // Prepare the data as a JSON object
                            const data = {
                                uuid: uuid,
                                message: inputValue,
                                fileData: fileData, // Include the file data as a Base64 string
                                filename: selectedFile.name,
                            };
                            axios.post('https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/messages', data)
                                .then((response) => {
                                    if (!response.ok) {
                                        messagestaus.innerText = response.status;
                                    }
                                    return response.data;
                                }).then(data => {
                                    setInputValue('');
                                    setSelectedFile(null);
                                    setSendingmessage(false)
                                    messagestaus.innerText = data.status;
                                    messagestaus.style = "color: #26d53e"
                                    setTimeout(() => {
                                        messagestaus.innerText = '';
                                        messagestaus.style = "color: red"
                                    }, 5000);
                                }).catch((error) => {
                                    setSendingmessage(false)
                                    messagestaus.innerText = error.response.data.status;
                                    messagestaus.style = "color: red"
                                    setTimeout(() => {
                                        messagestaus.innerText = '';
                                        messagestaus.style = "color: red"
                                    }, 5000);// Error saving message // Error saving message
                                });
                        }
                    } else {
                        // Prepare the data as a JSON object
                        const data = {
                            uuid: uuid,
                            message: inputValue,
                            fileData: null, // Include the file data as a Base64 string
                            filename: null,
                        };
                        axios.post('https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/messages', data)
                            .then((response) => {
                                if (!response.ok) {
                                    messagestaus.innerText = response.status;
                                }
                                return response.data;
                            }).then(response => {
                                setInputValue('');
                                setSelectedFile(null);
                                setSendingmessage(false)
                                messagestaus.innerText = response.status;
                                messagestaus.style = "color: #26d53e"
                                setTimeout(() => {
                                    messagestaus.innerText = '';
                                    messagestaus.style = "color: red"
                                }, 5000);
                            }).catch((error) => {
                                setSendingmessage(false)
                                messagestaus.innerText = error.response.data.status;
                                messagestaus.style = "color: red"
                                setTimeout(() => {
                                    messagestaus.innerText = '';
                                    messagestaus.style = "color: red"
                                }, 5000);// Error saving message
                            });
                    }
                } else {
                    messagestaus.style = "color: yellow";
                    messagestaus.innerText = "Please wait " + messageWaitTime + " more minutes before sending another message"; //
                }
            } else {
                messagestaus.innerText = "Enter some message to send."
            }
        } else {
            messagestaus.innerText = "You need to login before to sending message."
        }

    }
    useEffect(() => {
        // Listen for new messages
        onValue(messagesRef, (snapshot) => {
            const messages = snapshot.val();
            if (!messages) {
                var messageElement = document.createElement('div');
                messageElement.style = "text-align: -webkit-center;"
                messageElement.innerHTML = "No messages available yet."
                var chatMessages = document.getElementById('chat-messages');
                chatMessages.appendChild(messageElement);
            } else {
                setTotalmessages(messages);
                if (messages) {
                    setMessages(messages);
                }
            }

        });
    }, [messageInputRef, messagesRef])

    function CheckMessageTiming() {
        const matchingMessages = [];
        if (totalmessages) {
            Object.keys(totalmessages).forEach((key) => {
                const message = totalmessages[key];
                if (message.uuid === uuid) {
                    matchingMessages.push(message);
                }
            });

            if (matchingMessages[matchingMessages.length - 1]) {
                const createdDate = new Date((matchingMessages[matchingMessages.length - 1]).createdDate);
                const currentTime = new Date();

                const timeDiff = currentTime - createdDate;
                const minutesDiff = Math.floor(timeDiff / 1000 / 60);
                messageWaitTime = 1 - minutesDiff

                const isMoreThan10MinutesAgo = minutesDiff >= 1;

                return isMoreThan10MinutesAgo
            }
        } else {
            return true;
        }

    }

    // useEffect(() => {
    //     if (audioData && recording) {
    //         submitAudio();
    //     }
    // }, [audioData]);


    const submitAudio = async (audioBlob1) => {
        var messagestaus = document.getElementById('messagestaus');
        const uuid = localStorage.getItem('uuid');
        if (uuid) {
            setSendingmessage(true)
            // Convert the Blob to a base64-encoded string
            if (audioBlob1) {
                const audioDuration = `${Math.floor(Timing.current / 60).toString().padStart(2, '0')}:${(Timing.current % 60).toString().padStart(2, '0')}`;
                const audioBlob = audioBlob1;
                const fileReader = new FileReader();
                fileReader.readAsDataURL(audioBlob);
                fileReader.onloadend = async () => {
                    const audiobase64Data = await fileReader.result.split(',')[1];
                    if (audiobase64Data) {
                        if (CheckMessageTiming()) {
                            messagestaus.innerText = '';
                            const data = {
                                uuid: uuid,
                                message: inputValue,
                                fileData: audiobase64Data,
                                filename: uuid,
                                duration: audioDuration
                            };
                            setAudioData(null);
                            axios.post('https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/save-audio-messages', data)
                                .then((response) => {
                                    if (!response.ok) {
                                        messagestaus.innerText = response.status;
                                    }
                                    return response.data;
                                }).then(data => {

                                    setSendingmessage(false)
                                    setInputValue('');
                                    setSelectedFile(null);
                                    messagestaus.innerText = data.status;
                                    messagestaus.style = "color: #26d53e"
                                    setTimeout(() => {
                                        messagestaus.innerText = '';
                                        messagestaus.style = "color: red"
                                    }, 5000);
                                }).catch((error) => {
                                    setSendingmessage(false)
                                    messagestaus.innerText = error.response.data.status;
                                    messagestaus.style = "color: red"
                                    setTimeout(() => {
                                        messagestaus.innerText = '';
                                        messagestaus.style = "color: red"
                                    }, 5000);
                                });
                        } else {
                            setSendingmessage(false)
                            messagestaus.style = "color: yellow";
                            messagestaus.innerText = "Please wait " + messageWaitTime + " more minutes before sending another message"; //
                        }
                    } else {
                        setSendingmessage(false)
                        messagestaus.innerText = "Please try again.";
                    }
                }
            } else {
                setSendingmessage(false)
                messagestaus.innerText = "Please try again.";
            }
        } else {
            messagestaus.innerText = "You need to login before to sending message."
        }
    }


    return (
        <>
            <Helmet>
                <title>Infinity Trader - Live Chat</title>
            </Helmet>
            {audioData && null}
            <div className="page-content">
                <section className="content-inner" >
                    <div >
                        <div className="row " >
                            <div className="parent-container">
                                <div className="chat-container">
                                    <h4 style={{ textAlign: 'center' }}><strong>Welcome to REAL WORLD</strong></h4>
                                    <div className='chat-box'>
                                        <div>
                                            <ChatMessages messages={messages} uuid={uuid} adminName={adminName} />
                                        </div>
                                        <form id="messageform" onSubmit={sendMessage}>
                                            <div id="msgfields">
                                                {/* <button onClick={handleToggleEmojiPicker}>😀</button> */}

                                                <input type="text" onChange={handleInputChange} value={inputValue} id="messageinput" placeholder="Type a message..." />
                                                <label htmlFor="fileInput" className='chatAttachment'>
                                                    {!recording && <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleFileSelect}
                                                        style={{ display: 'none' }}
                                                        id="fileInput"
                                                    />}
                                                    {recording ?
                                                        <span>{`${Math.floor(timer / 60).toString().padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}</span>
                                                        :
                                                        <i className="fa fa-paperclip" style={{ fontSize: '20px', padding: '5px 5px 5px 5px', color: '#d3d3d3' }}></i>
                                                    }
                                                </label>
                                                {sendingmessage
                                                    ?
                                                    <button type="button" className="btn-send" id="send-button">
                                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: '17px', color: '#2c0191' }}></i>
                                                    </button>
                                                    :

                                                    (inputValue || selectedFile) ?
                                                        <button type="button" className="btn-send" id="send-button" onClick={sendMessage}>
                                                            <i className="fa fa-send-o" style={{ fontSize: '17px', color: '#2c0191' }}></i>
                                                        </button>
                                                        :
                                                        recording ? (
                                                            <div className="recording-container">
                                                                <div className="btn-container">
                                                                    <button type="button" className="btn-send" id="send-button" onClick={stopRecording}>
                                                                        <i className="fa fa-send-o" style={{ fontSize: '17px', color: '#2c0191' }}></i>
                                                                    </button>
                                                                    {showDeleteButton && (
                                                                        <button type="button" className=" btn-send btn-delete" id="delete-button" onClick={DeleteRecording}>
                                                                            <i className="fa fa-trash-o" aria-hidden="true" style={{ fontSize: '17px', color: 'red' }}></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <button type="button" className="btn-microphone" id="send-button" onClick={handleMicrophoneButtonClick}>
                                                                <i className="fa fa-microphone" style={{ fontSize: '17px', color: '#2c0191' }}></i>
                                                            </button>
                                                        )}
                                            </div>
                                        </form>
                                        {/* {showEmojiPicker && (
                                            <Picker
                                                data={data}
                                                onEmojiSelect={handleEmojiSelect}
                                                style={{
                                                    position: "absolute",
                                                    marginTop: "465px",
                                                    // marginLeft: -40,
                                                    maxWidth: "320px",
                                                    borderRadius: "20px",
                                                }}
                                                theme="dark"
                                            />
                                        )} */}
                                        <div id="messagestaus"></div>
                                        {selectedFile && <div style={{ textAlign: 'center' }}>Selected file: {selectedFile.name}</div>}
                                    </div>
                                </div>
                                {/* <div class="majority"><Majority /></div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default UserLiveChat;