import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import pdfUrl from '../../FreeTutorials/INFINITYTRADER.pdf'
import { Helmet } from 'react-helmet';
// Set the worker path for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const FreeTutoriale = () => {
    // const pdfUrl = "https://cors-anywhere.herokuapp.com/https://firebasestorage.googleapis.com/v0/b/infinitytrader-f65ff.appspot.com/o/INFINITYTRADER.pdf?alt=media&token=84f1e25a-b1aa-4bd5-8dde-6a3e5a1d0b22";

    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchTotalPages = async () => {
            const response = await fetch(pdfUrl);
            const arrayBuffer = await response.arrayBuffer();
            const pdf = await pdfjs.getDocument(arrayBuffer).promise;
            setNumPages(pdf.numPages); // Get the total number of pages in the PDF
        };

        fetchTotalPages();
    }, []);

    const handleNextPage = () => {
        if (currentPage < numPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="pdfviewer" style={{ marginTop: '100px', overflow: 'scroll' }}>
            <Helmet>
                <title>Infinity Trader - FreeTutorials</title>
            </Helmet>
            <Document
                file={pdfUrl}
                className='pdfviewer'>
                <Page pageNumber={currentPage} renderTextLayer={false} />
            </Document>
            <div style={{ textAlign: 'center' }}>
                <button className='btn' onClick={handlePrevPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {numPages}</span>
                <button className='btn' onClick={handleNextPage} disabled={currentPage === numPages}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default FreeTutoriale;