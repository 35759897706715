import React from 'react';
import { useNavigate } from 'react-router-dom';

const BinaryPricing = () => {

    const navigate = useNavigate();
    const pricingBlog = [
        {
            price: '1000',
            title: 'Lite',
            title2: 'Life-includes:',
            content: 'Use our golden signals and increase your capital on monthly basis' // Replace with actual course content
        }
    ];

    const sendPayment = async () => {
        navigate('/slot-booking', { state: { courseAmount: pricingBlog[0].price, module: 'Binarysignals' } });
    };


    return (
        < div className='course-price-containser' >
            {pricingBlog.map((data, index) => (
                <div className="col-xl-4 col-lg-6 col-md-6 m-b30" key={index}>
                    <div className={`pricingtable-wrapper box-hover style-1`}>
                        <div className="pricingtable-inner bg-gradient">
                            {data.content}
                            <h3 className="pricingtable-title">
                                Golden Signals
                            </h3>
                            <div className="pricingtable-price">
                                <h2 className="pricingtable-bx text-primary">
                                    ₹{data.price}
                                    {/* <s className='original-price-strike'>₹1000</s> ₹750 <smal className='small'>/month</smal> */}
                                </h2>

                                <div className="btn btn-primary bookslot" onClick={sendPayment}>Checkout and Get Signals</div>

                                <h6>Overview</h6>
                                <div>
                                    <li>Optimised signals</li>
                                    <li>High Accuracy</li>
                                    <li>More than 20 signals each asset every day.</li>
                                    <li>Binomo Crypto IDX signals available</li>
                                </div>

                            </div>
                            {/* <div className='offer-tag'>25% off</div> */}
                        </div>
                    </div>

                </div>
            ))
            }

        </div >
    )
}

export default BinaryPricing;