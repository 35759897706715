import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PageLayout from '../../layouts/PageLayout';
//images
import pic1 from '../../assets/images/forexbot/unzipping-your-EA.jpg';
import pic2 from '../../assets/images/forexbot/Selecting-Open-Data-Folder-from-File-Menu-in-MT4.jpg';
import pic3 from '../../assets/images/forexbot/Selecting-the-EA-files.jpg';
import pic4 from '../../assets/images/forexbot/Copying-the-EA-files.jpg';
import pic5 from '../../assets/images/forexbot/Configuring-EA-to-start.jpg';
import pic6 from '../../assets/images/forexbot/Starting-the-EA.jpg';
import pic7 from '../../assets/images/forexbot/Configuring-the-EA.jpg';
import pic8 from '../../assets/images/forexbot/EA-settings-screen.jpg';
import pic9 from '../../assets/images/forexbot/EA-Inputs-settings.jpg';

import Shape1 from '../../assets/images/home-banner/shape1.png';
import videobox from '../../assets/images/about/videobx.png';
import ModalVideo from 'react-modal-video'
import ForexPricing from '../../components/Forex/ForexPricing';
const ForexBot = () => {


    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <Helmet>
                <title>Infinity Trader - Forex AutoBot</title>
            </Helmet>
            <div className="page-content">
                <PageLayout pageTitle="Forex AutoBot" />
                <section className="content-inner">
                    <div className="container">
                        <div className="row">
                            <div>
                                <h4>How to open FXTM account</h4>
                                <section className="content-inner p-0 video-bx-wrapper">
                                    <img className="bg-shape1" src={Shape1} alt="" />
                                    <div className="container wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="video-bx style-1">
                                            <div className="video-media">
                                                <img src={videobox} alt="" />
                                                <Link to={"#"} className="popup-youtube play-icon" onClick={() => setOpen(true)}>
                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.25 3.75L23.75 15L6.25 26.25V3.75Z" stroke="#2c0191" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div style={{ marginTop: '60px' }}>
                                <h2 style={{ textAlign: 'center' }}>Forex Automated Software</h2>
                                <p>
                                    Experience the power of automation with our cutting-edge Forex Automated Software. Designed to streamline your trading process, our software harnesses advanced algorithms and real-time market data to execute trades on your behalf. With years of experience and expertise in the field, we have developed a reliable and efficient system that identifies lucrative opportunities and executes trades with precision.
                                </p>

                                <h4>Key Features:</h4>
                                <ol>
                                    <li>
                                        <strong style={{ color: "#1C2E9E" }}>Optimal Trading Signals:</strong> Our Forex Automated Software generates optimized trading signals based on comprehensive market analysis. It scans multiple currency pairs, indicators, and price patterns to identify high-probability trading opportunities, ensuring that you stay ahead of the game.
                                    </li>
                                    <br></br>
                                    <li>
                                        <strong style={{ color: "#1C2E9E" }}>Risk Management:</strong> We understand the importance of risk management in trading. Our software incorporates intelligent risk management protocols, allowing you to set stop-loss and take-profit levels to protect your capital and maximize profits. You can customize the risk parameters according to your trading preferences and risk tolerance.
                                    </li>
                                    <br></br>
                                    <li>
                                        <strong style={{ color: "#1C2E9E" }}>Seamless Integration:</strong> Our Forex Automated Software seamlessly integrates with popular trading platforms, providing you with a hassle-free trading experience. Whether you prefer MetaTrader, cTrader, or any other major platform, our software can be easily integrated, allowing you to automate your trades without any technical complications.
                                    </li>
                                    <br></br>
                                    <li>
                                        <strong style={{ color: "#1C2E9E" }}>Real-Time Monitoring and Alerts:</strong> Stay updated on your trading activities with our real-time monitoring and alerts system. You can receive instant notifications on executed trades, profit and loss updates, and other important trading insights, enabling you to stay informed and make timely decisions.
                                    </li>
                                </ol>

                                <p>
                                    With our Forex Automated Software, you can save time, reduce human error, and capitalize on trading opportunities around the clock. Experience the efficiency and accuracy of automation while maintaining full control over your trading strategy. Trust in our software to enhance your trading performance and achieve your financial goals with greater ease.
                                </p>
                            </div>
                            <div>
                                <h2 style={{ textAlign: 'center' }}>How to Run Infinity Trader Forex BOT on MT4</h2>

                                <h4>Step One: Download and Unzip Your EA Files</h4>
                                <p>After downloading your EA files, you have to unzip them. An EA folder should include .ex4 file:</p>
                                <ul>
                                    {/* <li>*.mq4 — This is your EA’s source code. You need this file if you need to make any adjustments or updates to your Expert Advisor. This file can be seen in the MetaEditor.</li> */}
                                    <li>*.ex4 — This is the compiled file. This is the file that will launch your EA in the MetaTrader terminal.</li>
                                </ul>
                                <p>Expert Advisors that are more professional might include more files required for them to work correctly.</p>

                                <img src={pic1} alt="Unzipping your EA" />
                                <hr></hr>
                                <h4>Step Two: Copy Your EA Files into Your MT4 Terminal</h4>
                                <p>In order to run Expert Advisor on MT4, you have to copy your EA’s files to the MT4 data folder. To do so, launch the MetaTrader 4. In the upper navigation menu, click on File and then click on Open Data Folder.</p>

                                <img src={pic2} alt="Selecting Open Data Folder from File Menu in MT4" />

                                <p>The window opening will be your MT4 data folder:</p>
                                <img src={pic3} alt="Selecting the EA files" />

                                <p>In the data folder, open MQL4 and then Experts. Copy your EA files and paste them into this folder.</p>
                                <p>Copying the EA files</p>
                                <img src={pic4} alt="Copying the EA files" />

                                <p>After copying the files, restart the MT4 terminal. Your EA is running on your MT4 terminal. You can find it in the Navigator window, under the Expert Advisors tab.</p>
                                <hr></hr>
                                <h4>Step Three: Check Your EA Settings</h4>
                                <p>Before using your EA, run this quick check to make sure everything is up and running. In the upper navigation menu, click on Tools and then on Options.</p>

                                <img src={pic5} alt="Configuring EA to start" />

                                <p>In the opened window, click on Expert Advisors and check the factors below:</p>
                                <ul>
                                    <li>Allow automated trading is enabled</li>
                                    <li>Allow DLL imports is enabled if your EA uses *.dll files</li>
                                </ul>
                                <p>Note: If your EA is designed for a specific time period, set Disable automated trading when the chart’s symbol or period has been changed.</p>

                                <img src={pic6} alt="Starting the EA" />

                                <p>Now your EA is ready to start.</p>
                                <hr></hr>
                                <h4>Step Four: Run Expert Advisor on MT4</h4>
                                <p>Before starting your Expert Advisor, make sure the chart with the asset and time frame you want to use your EA on is open. Open Navigator, and under Expert Advisors, click on your EA.</p>

                                <img src={pic7} alt="Configuring the EA" />

                                <p>Double-click on the EA, and a settings window will open. Under the Common tab, make sure your EA setting is checked like the picture below:</p>
                                <img src={pic8} alt="EA settings screen" />

                                <p>Note: Allow DLL imports should be enabled if your EA uses *.dll files.</p>

                                <p>Under the Inputs tab, make sure all the right parameters according to your trading strategy are there.</p>
                                <img src={pic9} alt="EA Inputs settings" />

                                <p>Then click on OK, and your EA will start to work!</p>
                            </div>
                            {/* <GetBot /> */}
                            {/* <button className="btn btn-primary" onClick={downloadFile} >Download Forex Bot</button> */}
                        </div>
                    </div>
                </section>
                <ForexPricing />
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="XZ21JWyJ7Qw" onClose={() => setOpen(false)} />
        </>
    )
}
export default ForexBot;