import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

import bg6 from './../assets/images/background/bg6.jpg';
import axios from 'axios';

const Register = (props) => {
    const nav = useNavigate();

    const userid = localStorage.getItem('userid');
    // Automatic redirection to home page if user hasUserId and accesses login page
    useEffect(() => {
        if (userid) {
            nav('/');
        }
    }, [userid, nav]);


    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    // const [binomoId, setBinomoId] = useState('');
    const [email, setEmail] = useState('');
    let errorsObj = { name: '', mobile: '', email: '', password: '', checkbox: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');

    const [status, setStatus] = useState(false);
    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        var validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (name.trim() === '') {
            errorObj.name = 'Name is Required';
            error = true;
        }
        if (mobile === '') {
            errorObj.mobile = 'Mobile Number is Required';
            error = true;
        } else if (mobile.length < 10) {
            errorObj.mobile = 'Mobile Number should be 10 digits';
            error = true;
        }
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        } else if (!validRegex.test(email)) {
            errorObj.email = 'Invalid email address'
            error = true;
        }
        // if (binomoId === '') {
        //     errorObj.binomoId = 'BinomoId is Required';
        //     error = true;
        // }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        var checkboxfield = document.getElementById("check2");
        if (!(checkboxfield.checked)) {
            errorObj.checkbox = 'Accept the Terms of Service and policies';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        } else {
            // Create the request body
            var requestBody = {
                name: name,
                email: email.toLowerCase(),
                password: password,
                // binomoID: binomoId,
                mobile: mobile
            };

            // Make the API request
            axios.post("https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/users", requestBody)
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            alert(response.status);
                            return;
                        }
                    }
                    return response.json();
                })
                .then(data => {
                    if (data?.status === "User added successfully.") {
                        setName('');
                        setEmail('');
                        setMobile('');
                        setPassword('');
                        // setBinomoId('');
                        checkboxfield.click();
                        setStatus(true);
                        setTimeout(() => {
                            setStatus(false);
                            nav("/login");
                        }, 3000);
                    }
                })
                .catch(error => {
                    console.error(error.response.data.status);
                    alert(error.response.data.status);
                });
        };

        // dispatch(loadingToggleAction(true));
        // dispatch(signupAction(email, password, navigate));
    }
    return (
        <>
            <div className="browse-job login-style3">
                <div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "auto" }}>
                    <div className="row gx-0" style={{ justifyContent: "center" }}>
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 ">
                            <div id="mCSB_1" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" >
                                <div id="mCSB_1_container" className="mCSB_container" style={{ position: "relative", marginTop: "80px", left: "0", dir: "ltr" }}>
                                    <div className="login-form style-2">
                                        <div className="card-body">
                                            {/* <div className="logo-header">
                                                    <Link to="/login" className="logo"><img src={logo} alt="" className="width-230 mCS_img_loaded" /></Link>
                                                </div> */}
                                            <nav className="nav nav-tabs border-bottom-0" >
                                                <div className="tab-content w-100" id="nav-tabContent">
                                                    <div className="tab-pane active show fade">
                                                        {props.errorMessage && (
                                                            <div className=''>
                                                                {props.errorMessage}
                                                            </div>
                                                        )}
                                                        {props.successMessage && (
                                                            <div className=''>
                                                                {props.successMessage}
                                                            </div>
                                                        )}
                                                        <form className="dz-form py-2" onSubmit={onSignUp}>
                                                            <h3 className="form-title">Sign Up</h3>
                                                            <div className="dz-separator-outer m-b5">
                                                                <div className="dz-separator bg-primary style-liner"></div>
                                                            </div>
                                                            <p>Enter your personal details below: </p>
                                                            <div className="form-group mt-3">
                                                                {/* <input name="dzName" required="" className="form-control" placeholder="Full Name" type="text" /> */}
                                                                <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Full Name" />
                                                                {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                                            </div>
                                                            <div className="form-group mt-3">
                                                                <input value={mobile} onChange={(e) => setMobile(e.target.value.trim())} className="form-control" placeholder="Mobile Number" />
                                                                {errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
                                                            </div>
                                                            <div className="form-group mt-3">
                                                                <input value={email} onChange={(e) => setEmail(e.target.value.trim())} className="form-control" placeholder="Email ID" />
                                                                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                                            </div>

                                                            {/* <div className="form-group mt-3">
                                                                <input value={binomoId} onChange={(e) => setBinomoId(e.target.value.trim())} className="form-control" placeholder="Binomo ID " />
                                                                {errors.binomoId && <div className="text-danger fs-12">{errors.binomoId}</div>}
                                                            </div> */}

                                                            <div className="form-group mt-3">
                                                                <input value={password} onChange={(e) => setPassword(e.target.value.trim())} className="form-control" placeholder="passowrd" />
                                                                {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                                            </div>
                                                            {/* <div className="form-group mt-3 mb-3">
																	<input name="dzName" required="" className="form-control" placeholder="Re-type Your Password" type="password" />
																</div> */}
                                                            <div className="mb-3 mt-3">
                                                                {errors.checkbox && <div className="text-danger fs-12">{errors.checkbox}</div>}
                                                                <span className="form-check float-start me-2">
                                                                    <input type="checkbox" className="form-check-input mt-0" id="check2" name="example1" />
                                                                    <label className="form-check-label d-unset" htmlFor="check2">I agree to the</label>
                                                                </span>
                                                                <p><label ><Link to={"/terms-and-conditions"} style={{ color: '#003ae7' }}>Terms of Service & Privacy Policy</Link></label></p>
                                                            </div>
                                                            <div>
                                                                {status && <div className="text-success fs-12">Registration Successfull, Please login to continue.</div>}
                                                            </div>
                                                            <div className="form-group clearfix text-left">
                                                                <NavLink to="/login" className="btn btn-primary outline gray" type="button">Back</NavLink >
                                                                <button type="submit" className="btn btn-primary float-end">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                        {/* <div className="card-footer">
                                                <div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
                                                    <div className="col-lg-12 text-center">
                                                        <span> © Copyright by
                                                            <span
                                                                className={`heart ${heartActive ? "" : "heart-blast"}`}
                                                                onClick={() => setHeartActive(!heartActive)}
                                                            ></span>
                                                            <a href="https://www.dexignzone.com/" target="_blank"> DexignZone </a> All rights reserved.</span>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Register;