import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const AddBinomoID = () => {
    const navigate = useNavigate();
    const [binomoid, setAcnumber] = useState('');
    const [successstatus, setSuccessstatus] = useState(false);
    const [errorstatus, setErrorstatus] = useState(false);
    const [message, setMessage] = useState('');

    let errorsObj = { binomoid: '' };
    const [errors, setErrors] = useState(errorsObj);
    // const dispatch = useDispatch();

    function onSubmit(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (!localStorage.getItem('uuid')) {
            errorObj.binomoid = 'You need to login before submitting BinomoID';
            error = true;
        }
        else if (binomoid === '') {
            errorObj.binomoid = 'Binomo ID is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        } else {
            var requestBody = {
                uuid: localStorage.getItem('uuid'),
                binomoID: binomoid,
            };
            // Make the API request
            fetch("https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/add-binomoid", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            // alert("User already exist with this Binomo ID");
                            return response.json();
                        }
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data.error, data.status);
                    if (data.error) {
                        setErrorstatus(true);
                        setSuccessstatus(false);
                        setMessage(data.error);
                    } else if (data.status) {
                        setSuccessstatus(true);
                        setErrorstatus(false);
                        setMessage(data.status);
                    }
                }).catch(err => {

                })
        }

    }
    return (
        <>
            <div className="row gx-0" style={{ justifyContent: "center", marginTop: '30px' }}>
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                    <form className=" dz-form pb-3" style={{ lineHeight: '30px' }} onSubmit={onSubmit}>
                        <h4 className="form-title m-t0" style={{ textAlign: 'center', color: 'white' }}>Register your Binomo ID</h4>
                        <div className="dz-separator-outer m-b5">
                            <div className="dz-separator bg-primary style-liner"></div>
                        </div>
                        <div className="form-group mb-3">
                            <input type="number" className="form-control" placeholder="Binomo ID" value={binomoid} onChange={(e) => setAcnumber(e.target.value)} />
                            {errors.binomoid && <div className="text-danger fs-12">{errors.binomoid}</div>}
                            {errorstatus && <div className="text-danger fs-12">{message}</div>}
                            {successstatus && <div className="text-success fs-12">{message}</div>}
                        </div>
                        <div style={{ textAlign: 'center' }}><strong style={{ color: 'red' }}>* </strong>Binomo ID cannot be changed once added, So provide valid Binomo ID</div>
                        <div className="form-group text-left mb-5">
                            <div style={{ textAlign: 'center' }}><button type="submit" className="btn btn-primary dz-xs-flex m-r5">Submit</button></div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    )
};

export default AddBinomoID;