import React from 'react';

//Images
import Icon9 from './../../assets/images/icons/icon9.svg';
import automations from './../../assets/images/icons/automation.png';
import automations1 from './../../assets/images/icons/automation1.png';

const cardData = [
    { image: automations1, title: 'Forex Automated System', description: 'Experience the power of automation with our cutting-edge Forex Automated Software.' },
    { image: Icon9, title: 'Binary Signals', description: 'Optimised signals with much accuracy on different time frames.' },
    { image: automations, title: 'Educational Tracks', description: 'Join our live session and gain your trading skills then get extraordinary profit with your skills.' }
    // { image: Icon12, title: 'Security', description: '' },
];

const OneStop = () => {
    return (
        <>
            {cardData.map((item, ind) => (
                <div className="col-xl-4 col-md-6 m-b60" key={ind}>
                    <div className="icon-bx-wraper style-3 text-center">
                        <div className="icon-media">
                            <img src={item.image} alt="" />
                        </div>
                        <div className="icon-content">
                            <h2 className="title" >{item.title}</h2>
                            <p className="m-b0">{item.description}</p>
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="col-xl-4 col-md-6 m-b60">
                <div className="icon-bx-wraper style-4" style={{ backgroundImage: "url(" + pic1 + ")" }}>
                    <div className="inner-content">
                        <div className="icon-media m-b30">
                            <img src={support1} alt="" />
                        </div>
                        <div className="icon-content">
                            <Link to={"/contact-us"} className="btn btn-primary">Call Us</Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default OneStop;