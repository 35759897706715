import React from 'react';
import Header from '../layouts/Header';

const HeaderLayout = (ComposedComponent) => (props) => (
    <>
        <Header {...props} />
        <ComposedComponent {...props} />
    </>
);

export default HeaderLayout;
