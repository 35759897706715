import React, { useEffect, useState } from 'react';
import AccordionWithHeader from '../../components/General/Accordion';
import cover from '../../assets/images/course/cover.jpg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CountdownTimer from '../../components/General/CountdownTimer'
import Pricing from '../../components/Courses/Pricing'
import { Helmet } from 'react-helmet';


const LiveClass = () => {
    const courseEndDate = new Date('2023-08-14');

    const faq = [
        {
            header: "How i can join the class?",
            content: "Register your seet in the available slot, Then you will be intimated via Email about the class link where you need to join and other updated"
        },
        {
            header: "Does everybody that applies get in?",
            content: "This can vary depending on the program and platform. Some programs may have a limited number of spots available, while others may accept all applicants. Please refer to the program details or contact the platform's customer support for more information on the selection process."
        },
        {
            header: "How programme is conducted?",
            content: "The program is typically conducted through a combination of live sessions and recorded courses. Students will have access to the program materials through the platform and will be able to interact with the instructor and other students."
        },
        {
            header: "Will we get recordings of the sessions?",
            content: "This can vary depending on the specific program and topic. Please refer to the program details for more information on any prerequisites or recommended knowledge."
        }
    ];

    const courseContent = [
        {
            title: 'Basics Course',
            content: [
                'what is Market?',
                'what is stock?',
                'what is stock market?',
                'why companies issue share?',
                'types for share market?',
                'Major market participants?',
                'process of trading?',
                'Major exchange in India?',
                'Market timing?',
                'market analysis',
                '-  Types of trend',
                '-  types of trading',
                '-  candlestick analysis',
                '-  support and resistance',
                '-  chart pattern analysis',
                '*  Triangle pattern',
                '*  Channel pattern',
                '*  Double bottom',
                '*  Triple top',
                '*  Triple bottom',
                '*  Rectangle',
                '*  Flag',
                '*  Wedge',
            ],
        },
        {
            title: 'Advanced Course',
            content: [
                'SMC-smart Money Concept',
                'market structure (HH - HL -LL-LH)',
                'supply and demand',
                'bos (Break of structure)',
                'choch( change of character)',
                'pullback',
                'flip',
                'sweep',
                'orderblock',
                'imbalance',
                'imbalance + orderblock',
                'liquidity ($$$)',
                'IDM-inducement',
                'mitigation',
                'Time frame',
                'Filter orderblock in all time frame',
                'How to take entry in Indian market',
                '-option chain',
                '-stick price in Nifty and Banknifty',
                'Bonus - How to analyze stock by daily news + Formula for stock pick for short term and long term.'
            ],
        },
    ];

    const daydata = (position, startindex, endindex) => {
        return (
            <ul className='bullet'>
                {courseContent[position].content.slice(startindex, endindex + 1).map((item, index) => (
                    <li key={index}>
                        {index === 10 ? <strong>{item}</strong> : item}
                    </li>
                ))}
            </ul>
        );
    }

    const dayWiseData = [
        { day: 'Day 1', title: 'Basics', content: daydata(0, 0, 3) },
        { day: 'Day 2', title: 'Basics', content: daydata(0, 4, 6) },
        { day: 'Day 3', title: 'Basics', content: daydata(0, 7, 9) },
        { day: 'Day 4', title: 'Q & A', content: <li>Market analysis pending topics <p><li>Doubt clarification about past session.</li></p> </li> },
        { day: 'Day 5', title: 'Advanced', content: daydata(1, 1, 3) },
        { day: 'Day 6', title: 'Advanced', content: daydata(1, 4, 8) },
        { day: 'Day 7', title: 'Advanced', content: daydata(1, 9, 14) },
        { day: 'Day 8', title: 'Advanced', content: daydata(1, 15, 18) },
        { day: 'Day 9', title: 'Advanced', content: daydata(1, 19, 20) },
        { day: 'Day 10', title: 'Q & A', content: 'Doubt clarification about past session.' },
    ];

    const [slidesToShow, setSlidesToShow] = useState(5);

    const sliderSettings = {
        arrows: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        infinite: false,
    };

    useEffect(() => {
        // Function to update the number of slides to show based on device size
        const updateSlidesToShow = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 1200) {
                setSlidesToShow(5);
            } else if (screenWidth >= 992) {
                setSlidesToShow(4);
            } else if (screenWidth >= 768) {
                setSlidesToShow(3);
            } else {
                setSlidesToShow(1);
            }
        };

        // Call the function when the component mounts and whenever the window is resized
        updateSlidesToShow();
        window.addEventListener('resize', updateSlidesToShow);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);
    return (
        <div>
            <Helmet>
                <title>Infinity Trader - Course</title>
            </Helmet>
            {/* Cover Image */}
            <div style={{ backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.3) 100%), url(${cover})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', color: '#fff', fontSize: '32px', fontWeight: 'bold' }}>
                    <div>Market Visionaries</div>

                    <div style={{ fontSize: 15, fontWeight: 500, padding: '10px 20% 0px 20%', textAlign: 'center' }}>Absolute goldmine for new traders: Start from personal finance to how to place your first trade, and much more for beginners.</div>

                </div>

            </div>

            <div className="coursedata">
                <div style={{ textAlign: 'center', padding: '30px 20px 0px 20px' }}>
                    <p><strong>Welcome </strong> to our comprehensive online trading course on SMC! Whether you're a beginner or an experienced trader, our program covers a wide range of topics, from the basics of trading to advanced strategies. </p>
                </div>

                <div >
                    <div className={`basic-course-content pricingtable-wrapper style-1 `}>
                        <div className="basic-course-content-inner">
                            <h4 className='dull-white-title'>
                                {courseContent[0].title}
                            </h4>
                            <div className='course-content'>
                                <div>
                                    <ul>
                                        {courseContent[0].content.map((item, index) => (
                                            index < 9 && <li key={index} style={{ lineHeight: '2.5' }}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <ul className='marketanalysis'>
                                        {courseContent[0].content.map((item, index) => (
                                            index === 9 ? <li key={index}><strong>{item}</strong></li> :
                                                index >= 9 && <li key={index} style={{ paddingLeft: '20px' }}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <div className={`basic-course-content pricingtable-wrapper box-hover style-1`}>
                        <div className="basic-course-content-inner">
                            <h4 className='dull-white-title'>
                                {courseContent[1].title}
                            </h4>
                            <div className="pricingtable-price">
                                <div>
                                    <strong className='dull-white-title'>SMC-smart Money Concept</strong>
                                    <div className='course-content'>
                                        <div>
                                            <ul>
                                                <li>market structure (HH - HL -LL-LH)</li>
                                                <li>supply and demand</li>
                                                <li>bos (Break of structure)</li>
                                                <li>choch( change of character)</li>
                                                <li>pullback</li>
                                                <li>flip</li>
                                                <li>sweep</li>
                                                <li>orderblock</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul>
                                                <li>imbalance</li>
                                                <li>imbalance+orderblock</li>
                                                <li>liquidity ($$$)</li>
                                                <li>IDM-inducement</li>
                                                <li>mitigation</li>
                                                <li>Time frame</li>
                                                <li>Filter orderblock in different time frames</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ul className='advancecoursebottomul'>
                                    {courseContent[1].content.map((item, index) => (

                                        index === 16 ?
                                            <li key={index}><strong className='dull-white-title'>{item}</strong></li>
                                            :
                                            (index > 16 && index <= 18) && <li style={{ paddingLeft: '8%' }} key={index}>{item}</li>
                                    ))}
                                    <li>
                                        <strong className='dull-white-title'>Bonus:</strong> how to analyze stock by daily news + Formula for stock pick for short term and long term.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '40px' }}>
                <h4 className='dull-white-title'> Know about BASIC topics on day wise</h4>
                <Slider {...sliderSettings}>
                    {dayWiseData.map((day, index) => (
                        index < 4 &&
                        <div className="daywisedata-col" key={index}>
                            <div className="daywisedata-grid-container bg-gradient">
                                <h5 className='dull-white-title'>{day.day}</h5>
                                <h6 style={{ color: '#7598ff' }}>{day.title}</h6>
                                <div><p style={{ textAlign: 'initial', paddingLeft: '15%', paddingTop: index === 3 && '20px' }}>{day.content}</p></div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div style={{ marginTop: '20px' }}>
                <h4 className='dull-white-title'>Know about Advanced topics on day wise</h4>
                <Slider {...sliderSettings}>
                    {dayWiseData.map((day, index) => (
                        index >= 4 &&
                        <div className="daywisedata-col " key={index}>
                            <div className="daywisedata-grid-container bg-gradient">
                                <h5 className='dull-white-title'>{day.day}</h5>
                                <h6 style={{ color: '#7598ff' }}>{day.title}</h6>
                                {index === 4 && <div><strong style={{ textAlign: 'initial' }}>Smart Money Concepts</strong></div>}
                                <div><p style={{ textAlign: 'initial', paddingLeft: '15%', paddingTop: index === 9 && '20px' }}>{day.content}</p></div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            {courseEndDate ? <div style={{ marginTop: '20px' }}><h4 className='dull-white-title'>Time Left to start class</h4><CountdownTimer endDate={courseEndDate} /> </div> : null}

            <div style={{ fontSize: 15, fontWeight: 500, textAlign: 'center', padding: '0px 20% 20px 20%' }}>
                Join us to master the art of trading and unlock new opportunities in the financial markets. Start your journey with us to financial success!
            </div>

            <Pricing courseEndDate={courseEndDate} />

            {/* FAQ */}
            <div className="container">
                <h4 className='dull-white-title'> Frequently Asked Questions</h4>
                <AccordionWithHeader data={faq} />
            </div>
        </div >
    )
}

export default LiveClass;
