import React, { useEffect, useRef, useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import discount1 from '../../assets/images/course/discount1.png'
import discount2 from '../../assets/images/course/discount2.png'
const ForexPricing = () => {
    const navigate = useNavigate();
    const pricingBlog = [
        {
            price: '10000',
            title: 'Lite',
            title2: 'Life-includes:',
            content: 'Use our Infinity Trase BS2 autobot to get average 10% to 20% profit per month' // Replace with actual course content
        }
    ];

    const sendPayment = async () => {
        navigate('/slot-booking', { state: { courseAmount: pricingBlog[0].price, module: 'forexbot' } });
    };
    return (
        < div className='course-price-containser' >
            {pricingBlog.map((data, index) => (
                <div className="col-xl-4 col-lg-6 col-md-6 m-b30" key={index}>
                    <div className={`pricingtable-wrapper box-hover style-1`}>
                        <div className="pricingtable-inner bg-gradient">
                            {data.content}
                            <h3 className="pricingtable-title">
                                To Purchase Forex Bot
                            </h3>
                            <div>Contact me on
                                <a className='text-decoration-underline fw-bold' href="https://t.me/infinitytrader004" target="_blank">  infinitytrader004</a>
                            </div>
                            <div className="pricingtable-price">
                                {/* <h2 className="pricingtable-bx text-primary"> ₹10,000</h2> */}
                                {/* <div className="btn btn-primary bookslot" onClick={sendPayment}>Checkout and get Forex Bot</div> */}

                                <h6>Forex BOT overview</h6>
                                <div>
                                    <li>Average 10% to 20% profit per month.</li>
                                    <li>Almost less drawdown.</li>
                                    <li>Trial period available.</li>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            ))
            }

        </div >
    )
}

export default ForexPricing;