import React, { useState } from 'react';


const Discord = () => {
    return (
        <div style={{ height: '100vh' }}>
            <iframe
                src="https://discordapp.com/widget?id=1147644046861402132&theme=dark"
                width="100%"
                height="100vh"
                allowtransparency="true"
                frameborder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
            </iframe>
        </div>
    )
}
export default Discord;