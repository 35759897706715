import React from 'react';
// import BinomoBot from '../AutoBots/Binomo/Infinity_Trader_BOT_Setup.exe';

import PageLayout from '../layouts/PageLayout';
import AddBinomoID from '../components/Binary/AddBinomoID'


const BinaryBot = () => {
    const downloadFile = () => {
        // window.location.href = BinomoBot;
    }
    return (
        <>
            <div className="page-content">
                <PageLayout pageTitle="Binomo Bot" />
                <section className="content-inner">
                    <div className="container">
                        <div className="row">

                            <div>
                                <h4>Welcome to Binary Bot - Your Ultimate Trading Assistant!</h4>
                                <p>
                                    Enhance your trading experience with Binary Bot, the cutting-edge automated software designed to optimize your trading strategies and maximize your profits. Whether you're a beginner or an experienced trader, our powerful software is here to assist you in achieving your financial goals.
                                </p>
                                <h4>Key Features:</h4>
                                <ol>
                                    <li>Smart Trading Algorithms: Our software utilizes advanced algorithms to analyze market trends and make informed trading decisions on your behalf. It constantly monitors the market, identifies profitable opportunities, and executes trades with precision.</li>
                                    <br></br>
                                    <li>Customizable Strategies: Binary Bot empowers you to create and customize trading strategies that suit your preferences and risk tolerance. With our intuitive interface, you can easily define your own indicators, signals, and conditions to automate your trading activities.</li>
                                    <br></br><li>Real-time Market Data: Stay updated with real-time market data and charts, allowing you to make informed decisions based on accurate information. Our software ensures that you have access to the latest market trends, asset prices, and trading volumes.</li>
                                    <br></br><li>Risk Management Tools: Protect your investments with our comprehensive risk management tools. Binary Bot enables you to set stop-loss orders, take-profit levels, and other risk parameters to manage your trades effectively and minimize potential losses.</li>
                                    <br></br><li>Seamless Integration: Our software seamlessly integrates with popular trading platforms, enabling you to execute trades directly from the Binary Bot interface. Connect your preferred broker account and take advantage of our software's advanced capabilities without any hassle.</li>
                                    <br></br><li>Dedicated Support: We are providing exceptional customer support. Whether you have technical inquiries or need assistance with utilizing the software effectively, we're here to help you every step of the way.</li>
                                </ol>
                                <p>
                                    Take your trading to the next level with Binary Bot and unlock the potential of automated trading.
                                </p>
                                <p>
                                    Ready to experience the power of automation? Discover how Binary Bot can revolutionize your trading journey! Download the Bot and start.
                                </p>
                            </div>
                            <div>
                                <h4>Steps To Start:</h4>
                                <ol>
                                    <li>1. Click on the download button and wait for the Bot file to get download.</li>
                                    <li>2. Place the Dowbloaded Bot file in your favourite location</li>
                                    <li>3. Extract the file by right clicking on it.</li>
                                    <li>4. Open the Data file(Main folder - Data folder - data file)</li>
                                    <li>5. Change the BinomoId to your BinomoID which is registed with us(Infinity Trader) and change your number of Martingale and martingale amount.</li>
                                    <li>6. Save the file using ctrl+s and close it.</li>
                                    <li>7. Now its time to start the BOT. Go to Main folder and double click the Infinity Trader BOT file</li>
                                    <li>Our Infinity Trader Binary Bot will start and your trading begins with automated system</li>
                                </ol>
                            </div>
                            <button className="btn btn-primary" onClick={downloadFile} >Download Binomo Bot</button>
                        </div>
                        <AddBinomoID />
                    </div>
                </section>
            </div>
        </>
    )
}
export default BinaryBot;