import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const VideoSlider = () => {
    const [videos, setVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const videosPerPage = 10;

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(
                    `https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/get-yt`
                );
                const data = await response.json();

                // Sort the videos based on view count
                // const sortedData = data.sort((a, b) => {
                //     const viewCountA = a.statistics?.viewCount || 0;
                //     const viewCountB = b.statistics?.viewCount || 0;
                //     return viewCountB - viewCountA;
                // });

                const items = data.map((video) => ({
                    title: video.snippet?.title || '',
                    viewCount: video.statistics?.viewCount || 0,
                    likesCount: video.statistics?.likeCount || 0,
                    commentsCount: video.statistics?.commentCount || 0,
                    key: `videoid-${video.id.videoId}`,
                    url: `https://www.youtube.com/embed/${video.id.videoId}`,
                }));
                setVideos(items);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        fetchVideos();
    }, []);

    const YoutubeSlide = ({ url, isSelected, video }) => (
        <>
            <ReactPlayer width="100%" url={url} playing={isSelected} autoPlay={false} />
            <div className="video-details">
                <br></br>
                {/* <h3>{video.title}</h3> */}
                <p><strong>Views: </strong>{video.viewCount} &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Likes: </strong>{video.likesCount}&nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Comments: </strong>{video.commentsCount}</p>

            </div>
        </>
    );

    const customRenderItem = (item, props) => React.createElement(item.type, { ...item.props, ...props });

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

    const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

    const customRenderThumb = (children) =>
        children.map((item) => {
            const videoId = getVideoId(item.props.url);
            return <img src={getVideoThumb(videoId)} alt='' />;
        });

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const startIndex = (currentPage - 1) * videosPerPage;
    const endIndex = startIndex + videosPerPage;
    const paginatedVideos = videos.slice(startIndex, endIndex);

    return (
        <div className='ytslider'>
            <Carousel
                renderItem={customRenderItem}
                renderThumbs={customRenderThumb}
                showStatus={false}
                showIndicators={false}
            >
                {paginatedVideos.map((video) => (
                    <YoutubeSlide key={video.key} url={video.url} video={video} />
                ))}

            </Carousel>
            <div>
                <button className="ytbutton" onClick={handlePrevPage} disabled={currentPage === 1}>
                    &laquo;  Previous Page
                </button>
                <button className="ytbutton" onClick={handleNextPage} disabled={endIndex >= videos.length}>
                    Next Page &raquo;
                </button>
            </div>
        </div>
    );
};

export default VideoSlider;
