import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import LogoWhite from './../assets/images/logo-white.png';

const Header = () => {
    var userid = localStorage.getItem('userid');
    var isLiveChatpaid = localStorage.getItem('isLiveChatpaid');
    var isActiveCourseUser = localStorage.getItem('isActiveCourseUser');
    /* for sticky header */
    const [headerFix, setheaderFix] = React.useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setheaderFix(window.scrollY > 50);
        });
    }, []);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showForexMenu, setShowForexMenu] = useState(false);
    const [showlearningMenu, setShowlearningMenu] = useState(false);



    function Logout() {
        localStorage.removeItem('userid');
        localStorage.removeItem('uuid');
        localStorage.removeItem('isLiveChatpaid');
        localStorage.removeItem('isActiveCourseUser');

        userid = localStorage.getItem('userid');
        isLiveChatpaid = localStorage.getItem('isLiveChatpaid');
        isActiveCourseUser = localStorage.getItem('isActiveCourseUser');
    }
    return (
        <>
            <header className="site-header mo-left header header-transparent style-1">
                <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
                    <div className="main-bar clearfix">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <Link to={"/"} className="logo-dark"><img className="slogo" src={LogoWhite} alt="" /></Link>
                                <Link to={"/"} className="logo-light"><img className="slogo-light" src={LogoWhite} alt="" /></Link>
                            </div>

                            <button type="button"
                                className={`navbar-toggler  navicon justify-content-end ${sidebarOpen ? 'open' : 'collapsed'}`}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <div className="extra-nav">
                                <div className="extra-cell">
                                    {userid ? <div onClick={Logout}><NavLink to={"/login"} ><a className="btn btn-outline-primary text-white" target="_blank" rel="noreferrer" >Logout</a></NavLink></div>
                                        : <><div ><NavLink to={"/login"} ><a className="btn btn-outline-primary text-white" target="_blank" rel="noreferrer">Login</a></NavLink></div>
                                            <div><NavLink to={"/register"}><a className="btn btn-primary  btn-shadow btn-outline-primary" target="_blank" rel="noreferrer" >Register</a></NavLink></div></>}
                                </div>
                            </div>

                            <div className={`header-nav navbar-collapse collapse ${sidebarOpen ? "show" : ""}`} id="navbarNavDropdown" >
                                <div className="logo-header mostion">
                                    <NavLink to={"/"} className="logo-dark"><img className="slogo" src={LogoWhite} alt="" /></NavLink>
                                </div>
                                <ul className="nav navbar-nav navbar">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about-us">About Us</Link></li>
                                    {/* <li><Link to="/pricing">Pricing</Link></li> */}
                                    {/* <li className={`sub-menu-down ${showMenu ? "open" : ""}`} id="menushow"
                                        onClick={() => setShowMenu(!showMenu)}>
                                        <Link to={"#"}>Blog</Link>
                                        <ul className="sub-menu">
                                            <li><NavLink to={"/blog-list"}>Blog list</NavLink></li>
                                            <li><NavLink to={"/blog-grid"}>Blog grid</NavLink></li>
                                            <li><NavLink to={"/blog-details"}>Blog details</NavLink></li>
                                        </ul>
                                    </li> */}
                                    <li className={`sub-menu-down ${showMenu ? "open" : ""}`} id="menushow"
                                        onClick={() => setShowMenu(!showMenu)}>
                                        <Link to={"#"}>Binary</Link>
                                        <ul className="sub-menu">
                                            <li><NavLink to={"/binary-signals"}>Binary Signals</NavLink></li>
                                            {/* <li><NavLink to={"/binary-bot"}>Binary BOT</NavLink></li> */}
                                        </ul>
                                    </li>
                                    <li className={`sub-menu-down ${showForexMenu ? "open" : ""}`} id="menushow"
                                        onClick={() => setShowForexMenu(!showForexMenu)}>
                                        <Link to={"#"}>Forex</Link>
                                        <ul className="sub-menu">
                                            {/* <li><NavLink to={"/forex-signal"}>Forex Signals</NavLink></li> */}
                                            <li><NavLink to={"/forex-bot"}>Forex AutoBot</NavLink></li>
                                            <li><NavLink to={"/link-mt4-id"}>Link MT4 ID</NavLink></li>
                                            {/* <li><NavLink to={"/forex-copy-trade"}>Auto Trade</NavLink></li> */}
                                        </ul>
                                    </li>
                                    <li className={`sub-menu-down ${showlearningMenu ? "open" : ""}`} id="menushow"
                                        onClick={() => setShowlearningMenu(!showlearningMenu)}>
                                        <Link to={"#"}>Learning</Link>
                                        <ul className="sub-menu">
                                            <li><NavLink to={"/free-tutorials"}>Free Tutorials</NavLink></li>
                                            {/* <li><NavLink to={"/courses"}>Educational Tracks</NavLink></li> */}
                                        </ul>
                                    </li>
                                    {/* <li><Link to="https://thedarkworld.infinitytrader.org.in" target="_blank">Courses</Link></li> */}
                                    {(userid && (isLiveChatpaid || isActiveCourseUser)) && <li><NavLink to={"/user-live-chat"}>Live Chat</NavLink></li>}
                                    {/* {<li><NavLink to={"/publicchat"}>Public Chat</NavLink></li>} */}
                                    {/* {<li><NavLink to={"/courses"}>Educational Tracks</NavLink></li>} */}

                                    {/* <li><NavLink to={"/technicals"}>Technicals</NavLink></li> */}
                                    {(sidebarOpen && localStorage.getItem('uuid')) && <li><NavLink to={"/login"} onClick={Logout}>Logout</NavLink></li>}
                                    {(sidebarOpen && !(localStorage.getItem('uuid'))) && <li><NavLink to={"/login"} onClick={Logout}>Login</NavLink></li>}

                                </ul>

                                <div className="header-bottom">
                                    <div className="dz-social-icon">
                                        <ul>
                                            <li><a style={{ color: '#fff' }} target="_blank" className="fab fa-facebook-f" rel="noreferrer" href="https://t.me/Infinitytradersss"></a></li>{" "}
                                            <li><a style={{ color: '#fff' }} target="_blank" className="fab fa-instagram fa-lg" href="https://instagram.com/infinity___trader?igshid=MmIzYWVlNDQ5Yg=="></a></li>{" "}
                                            <li><a style={{ color: '#fff' }} target="_blank" className="fab fa-youtube fa-lg" href="https://youtube.com/@infinitytrader6562"></a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </header>
        </>
    )
}
export default Header;