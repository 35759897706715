import React from 'react';

class OurMission extends React.Component {
    render() {
        return (
            <div style={{ padding: "10%" }}>
                <h2>Our Mission</h2>
                <p>At Infinity Trader, our mission is to empower traders like you to navigate the financial markets with confidence and achieve their financial goals. We are driven by a passion for trading and a commitment to excellence, and we strive to provide you with the tools, knowledge, and support necessary for success.</p>
                <p>Our mission is built on three core principles:</p>
                <ol>
                    <li><strong>Cutting-Edge Solutions:</strong> We provide advanced trading software and optimized signals, leveraging technology to maximize profitability.</li>
                    <br></br><li><strong>Education and Empowerment:</strong> We offer educational resources and classes to equip traders with knowledge and strategies.</li>
                    <br></br><li><strong>Trust and Integrity:</strong> We prioritize transparency, ethical practices, and reliable support to build trust with our clients.</li>
                </ol>
                <p>By embodying these principles, we aim to be your trusted partner in your trading journey. We are here to support you, guide you, and celebrate your successes. Together, let's navigate the markets, unlock your trading potential, and create a brighter financial future.</p>
            </div>
        );
    }
}

export default OurMission;
