import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import play from '../../../assets/svg/play.svg'
import pause from '../../../assets/svg/pause.svg'

function PlayButton({ isPlaying, setIsPlaying, audioRef }) {
    useEffect(() => {
        const currentAudioRef = audioRef.current;
        const handleAudioEnd = () => {
            setIsPlaying(false);
        };

        currentAudioRef.addEventListener('ended', handleAudioEnd);

        return () => {
            currentAudioRef.removeEventListener('ended', handleAudioEnd);
        };
    }, [setIsPlaying, audioRef]);

    return (
        <img style={{ backgroundColor: '#fff', borderRadius: '100%', width: '30px', padding: '7px', height: '30px' }} src={isPlaying ? pause : play} onClick={() => setIsPlaying(!isPlaying)}></img>
    );
}

PlayButton.propTypes = {
    isPlaying: PropTypes.bool.isRequired,
    setIsPlaying: PropTypes.func.isRequired,
    audioRef: PropTypes.object.isRequired,
};

export default PlayButton;
