import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageLayout from './../layouts/PageLayout';

const ContactUs = () => {
    const nav = useNavigate();
    // const submitHandler = (e) => {
    //     e.preventDefault();
    //     nav("/");
    // };
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    let errorsObj = { name: '', mobile: '', email: '', message: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [message, setMessage] = useState('');

    const [status, setStatus] = useState(false);
    const [statusText, setStatusText] = useState('');

    function submitHandler(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        var validRegex = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
        if (name.trim() === '') {
            errorObj.name = 'First Name is Required';
            error = true;
        }
        if (mobile === '') {
            errorObj.mobile = 'Mobile Number is Required';
            error = true;
        } else if (mobile.length < 10) {
            errorObj.mobile = 'Mobile Number should be 10 digits';
            error = true;
        }
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        } else if (!validRegex.test(email)) {
            errorObj.email = 'Invalid email address'
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        } else {
            // Create the request body
            var requestBody = {
                firstname: name,
                lastname: lastname,
                email: email.toLowerCase(),
                mobile: mobile,
                message: message
            };

            // Make the API request
            fetch("https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/save-contactus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            alert("User already exist with this Binomo ID");
                            return;
                        }
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.status) {
                        setStatusText(data.status)
                        setName('');
                        setEmail('');
                        setMobile('');
                        setMessage('');
                        setStatus(true);
                        setTimeout(() => {
                            setStatus(false);
                            nav("/");
                        }, 3000);
                    }
                })
                .catch(error => {
                    // Handle any errors
                    console.error(error);
                });
        };
    };
    return (
        <>
            <div className="page-content">
                <PageLayout desc={false} pageTitle="Contact Us" />
                <section className="content-inner contact-form-wraper style-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-5 m-b30">
                                <div className="info-box">
                                    <div className="info">
                                        <h2>Contact Information</h2>
                                        <p className="font-18">Fill up the form and our team will get back to you within 24 hours.</p>
                                    </div>

                                    <div className="widget widget_about">
                                        <div className="widget widget_getintuch">
                                            <ul>
                                                <li>
                                                    <i className="fa fa-phone"></i>
                                                    <span>1800-123-4567<br />+91 987-654-3210</span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-envelope"></i>
                                                    <span>info@example.com <br />services@gmail.com</span>
                                                </li>
                                                <li>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    <span>valasaravakkam, Chennai <br />Tamilnadu, India</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="social-box dz-social-icon style-3">
                                        <h6>Our Socials</h6>
                                        <ul className="social-icon">
                                            <li><a className="social-btn fab fa-telegram fa-lg" href="https://t.me/Infinitytradersss"></a> </li>{" "}
                                            <li><a className="social-btn fab fa-instagram fa-lg" href="https://instagram.com/infinity___trader?igshid=MmIzYWVlNDQ5Yg=="> </a></li>{" "}
                                            <li><a className="social-btn fab fa-youtube fa-lg" href="https://youtube.com/@infinitytrader6562"></a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-7 col-lg-7">
                                <div className="contact-box">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="mb-4">
                                                <h2 className="mb-0">Get In touch</h2>
                                                <p className="mb-0 font-18 text-primary">We are here for you. How we can help?</p>
                                            </div>
                                            <form className="dzForm" onSubmit={(e) => submitHandler(e)}>
                                                <div className="dzFormMsg"></div>
                                                <input type="hidden" className="form-control" name="dzToDo" value="Contact" />

                                                <div className="row">
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="First Name" />
                                                        {errors.name && <div className="text-danger fs-12">{errors.name}</div>}

                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input name="dzLastName" type="text" className="form-control" placeholder="Last Name" />
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        {/* <input name="dzEmail" type="text" className="form-control" placeholder="Email Address" /> */}
                                                        <input value={email} onChange={(e) => setEmail(e.target.value.trim())} className="form-control" placeholder="hello@example.com" />
                                                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                                    </div>
                                                    <div className="col-xl-6 mb-3 mb-md-4">
                                                        <input value={mobile} onChange={(e) => setMobile(e.target.value.trim())} className="form-control" placeholder="Mobile Number" />
                                                        {errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}

                                                    </div>
                                                    <div className="col-xl-12 mb-3 mb-md-4">
                                                        {/* <textarea name="dzMessage" className="form-control" placeholder="Message"></textarea> */}
                                                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" placeholder="Enter your message" />
                                                        {errors.message && <div className="text-danger fs-12">{errors.message}</div>}
                                                    </div>
                                                    <div className="text-success fs-12">{statusText}</div>
                                                    <div className="col-xl-12">
                                                        <button name="submit" type="submit" value="Submit" className="btn btn-primary">Submit Now</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default ContactUs;
