import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';

//images
import Shape1 from './../assets/images/home-banner/shape1.png';
import Shape3 from './../assets/images/home-banner/shape3.png';
import { Helmet } from 'react-helmet';

const PageLayout = ({ pageTitle, desc }) => {
    return (
        <>
            <div className="dz-bnr-inr style-1 text-center">
                <div className="container">
                    <div className="dz-bnr-inr-entry">
                        <h1>{pageTitle}</h1>
                        <nav className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/binary-signals"}>BinarySignals</Link></li>
                                <li className="breadcrumb-item active">{pageTitle}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <img className="bg-shape1" src={Shape1} alt="" />
                <img className="bg-shape2" src={Shape1} alt="" />
                <img className="bg-shape3" src={Shape3} alt="" />
                <img className="bg-shape4" src={Shape3} alt="" />
            </div>
        </>
    )
}


const Signals = () => {
    const asset = (new URLSearchParams(window.location.search)).get('asset')
    useEffect(() => {

        function displayLoading(location) {
            if (location === "signal") {
                // signalloader.classList.add("display");
            } else {
                // loader.classList.add("display");
            }
        }

        // hiding loading 
        function hideLoading(location) {
            if (location === "signal") {
                // signalloader.classList.remove("display");
            } else {
                // loader.classList.remove("display");
            }

        }
        // const loaderText = document.getElementById('loader-text');
        const texts = ['Loading...', 'Please wait...', 'Almost there...', 'Just a moment...', 'Fetching data...'];
        let currentIndex = 0;
        let ApiCallCompleted = false;

        // Set the initial loader text
        //loaderText.textContent = texts[currentIndex];
        //loaderText.style = "display: block"
        displayLoading("signal")

        // Update the loader text at intervals
        const startTime = new Date().getTime();

        const updateText = () => {
            //loaderText.textContent = texts[currentIndex];
            currentIndex = (currentIndex + 1) % texts.length; // Cycle through the texts
        };
        const checkElapsedTime = () => {
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - startTime;

            if (elapsedTime >= 30000 || ApiCallCompleted) { // Adjust the time threshold based on your needs
                // API call completed
                //loaderText.style = "display: none"
                //loaderText.textContent = '';
                hideLoading("signal")
            } else {
                updateText();
                setTimeout(checkElapsedTime, 2500); // Adjust the interval based on your needs
            }
        };

        const signalTable = document.getElementById('signal-table');
        const signalTableBody = signalTable.querySelector('tbody');

        const FiveMsignalTable = document.getElementById('FiveMsignal-table');
        const FiveMsignalTableBody = FiveMsignalTable.querySelector('tbody');

        const FifteenMsignalTable = document.getElementById('FifteenMsignal-table');
        const FifteenMsignalTableBody = FifteenMsignalTable.querySelector('tbody');
        // hide table
        signalTable.style.display = 'none';
        FiveMsignalTable.style.display = 'none';
        FifteenMsignalTable.style.display = 'none';

        const id = localStorage.getItem('uuid');
        var prevdate = localStorage.getItem('prevdate');
        if (!prevdate) {
            prevdate = 0;
        }
        const url = `https://us-central1-infinitytrader-f65ff.cloudfunctions.net/app/api/getsignal/${id}/${asset}/${prevdate}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data.length)
                // Populate table with signal data
                if (data.oneMSignal || data.fiveMSignal || data.fifteenMSignal) {

                    if (data.oneMSignal.length > 0) {
                        signalTableBody.innerHTML = '';
                        data.oneMSignal.forEach((signal, index) => {
                            const row = signalTableBody.insertRow();
                            row.insertCell().textContent = index + 1;
                            row.insertCell().textContent = asset;
                            row.insertCell().textContent = signal.time;
                            const direction = row.insertCell();
                            direction.textContent = signal.signal
                            direction.classList.add(signal.signal.toLowerCase());
                            row.insertCell().textContent = signal.result;
                        });
                        signalTableBody.insertRow();
                        // Show table
                        signalTable.style.display = 'table';
                    }
                    if (data.fiveMSignal.length > 0) {
                        //5min
                        FiveMsignalTableBody.innerHTML = '';

                        data.fiveMSignal.forEach((signal, index) => {
                            const row = FiveMsignalTableBody.insertRow();
                            row.insertCell().textContent = index + 1;
                            row.insertCell().textContent = asset;
                            row.insertCell().textContent = signal.time;
                            const direction = row.insertCell();
                            direction.textContent = signal.signal
                            direction.classList.add(signal.signal.toLowerCase());
                            row.insertCell().textContent = signal.result;
                        });
                        FiveMsignalTableBody.insertRow();
                        // Show table
                        FiveMsignalTable.style.display = 'table';
                    }
                    if (data.fifteenMSignal.length > 0) {
                        //5min
                        FifteenMsignalTableBody.innerHTML = '';

                        data.fifteenMSignal.forEach((signal, index) => {
                            const row = FifteenMsignalTableBody.insertRow();
                            row.insertCell().textContent = index + 1;
                            row.insertCell().textContent = asset;
                            row.insertCell().textContent = signal.time;
                            const direction = row.insertCell();
                            direction.textContent = signal.signal
                            direction.classList.add(signal.signal.toLowerCase());
                            row.insertCell().textContent = signal.result;
                        });
                        FifteenMsignalTableBody.insertRow();
                        // Show table
                        FifteenMsignalTable.style.display = 'table';
                    }

                } else {
                    // Error occurred, show error message
                    const errorMessage = `${data.error}`;
                    const errorElement = document.getElementById('error-message');
                    errorElement.style = { color: 'red' };
                    errorElement.textContent = errorMessage;
                }
                // Call the checkElapsedTime function after the API call is complete
                //loaderText.style = "display: none"
                //loaderText.textContent = '';
                hideLoading("signal")
                ApiCallCompleted = true;
                const signalwaitelement = document.getElementById('signal-wait-message');
                signalwaitelement.style.display = 'none';
            })
            .catch(error => {
                // Error occurred, show error message
                const errorMessage = `Error occurred: ${error.message}`;
                const errorElement = document.getElementById('error-message');
                errorElement.innerHTML = errorMessage;
                // Call the checkElapsedTime function after the API call is complete
                checkElapsedTime();
            });

    }, [asset]);

    function downloadsignals() {
        const tableId = document.getElementById('signal-table');
        const FivMtableId = document.getElementById('FiveMsignal-table');
        const body = document.getElementById('signal-grid');

        if (tableId.rows.length === 2 && FivMtableId.rows.length === 2) {
            alert("No signals available. unable to download.");
            return;
        }

        html2canvas(body).then(canvas => {
            // const watermarkText = 'Infinity Trader Signals';
            // const ctx = canvas.getContext('2d');

            // const watermarkColor = 'rgba(255, 255, 255, 0.3)';
            // ctx.fillStyle = watermarkColor;

            // const watermarkSize = 32; // Font size
            // const gapX = 400; // Gap between watermarks in the X direction
            // const gapY = 250; // Gap between watermarks in the Y direction
            // const angle = -30; // Slant angle in degrees

            // for (let y = 0; y < canvas.height; y += gapY) {
            //     for (let x = 0; x < canvas.width; x += gapX) {
            //         const yOffset = x * Math.tan(-angle * (Math.PI / 180));

            //         ctx.save();
            //         ctx.translate(x, y + yOffset);
            //         ctx.rotate(angle * (Math.PI / 180));

            //         ctx.font = `${watermarkSize}px Arial`;
            //         ctx.fillText(watermarkText, 0, 0);

            //         ctx.restore();
            //     }
            // }

            // Convert the canvas to a data URL
            const dataURL = canvas.toDataURL('image/png');

            // Create a link element and download the image
            const downloadLink = document.createElement('a');
            downloadLink.download = asset + '-Infinity-Trader-Signals.png';
            downloadLink.href = dataURL;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }).catch(error => {
            console.log(error);
        });
    }
    const userid = localStorage.getItem('userid')
    return (
        <>
            <Helmet>
                <title>Infinity Trader - {asset + " Signals"}</title>
            </Helmet>
            <div className="page-content">
                <PageLayout desc={false} pageTitle={asset + " Signals Details "} />
                <section className="content-inner" >
                    <div className="container">
                        <div className="row ">

                            <p id="signal-wait-message">Please Wait, we are fetching Signals</p>
                            <p id="error-message"></p>
                            <div id="loader-container">
                                <div id="signalloading"></div>
                                <p id="loader-text"></p>
                            </div>
                            {(userid === 'Din1008' || userid === 'Aru1015') && <div style={{ textAlign: 'center', marginBottom: '10px' }}><button className='btn btn-primary' id='download-btn' onClick={downloadsignals}>Download</button></div>}
                            <div id="signal-grid">

                                <div></div>
                                <table id="signal-table" className="styled-table" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th colspan="5" className="secondary-bg-color" style={{ color: "#ffffff" }}>1 Minute Signals</th>
                                        </tr>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Asset Name</th>
                                            <th>Time</th>
                                            <th>Direction</th>
                                            <th>Results</th>
                                        </tr>
                                    </thead>
                                    <tbody id="signal-body">
                                    </tbody>
                                </table>
                                <div></div>
                                <table id="FiveMsignal-table" className="styled-table" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th colspan="5" style={{ backgroundColor: "#1c2e9e", color: "#ffffff" }}>5 Minutes Signals</th>
                                        </tr>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Asset Name</th>
                                            <th>Time</th>
                                            <th>Direction</th>
                                            <th>Results</th>
                                        </tr>
                                    </thead>
                                    <tbody id="signal-body">
                                    </tbody>
                                </table>
                                <div></div>
                                <table id="FifteenMsignal-table" className="styled-table" style={{ display: "none" }}>
                                    <thead>
                                        <tr>
                                            <th colspan="5" style={{ backgroundColor: "#1c2e9e", color: "#ffffff" }}>15 Minutes Signals</th>
                                        </tr>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Asset Name</th>
                                            <th>Time</th>
                                            <th>Direction</th>
                                            <th>Results</th>
                                        </tr>
                                    </thead>
                                    <tbody id="signal-body">
                                    </tbody>
                                </table>
                                <div></div>
                                <div id="pagination" style={{ display: "none" }}>
                                    <button id="prevPage">Prev</button>
                                    <span id="currentPage"></span>
                                    <button id="nextPage">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </>
    )
}
export default Signals;